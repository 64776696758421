import { useCallback, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { Connectors, BinanceWalletNetworkIds } from "Connectors";
import Web3Utils from "web3-utils";
import useAppSnackbar from "./useAppSnackbar";
import { LOCAL_STORAGE_ITEM_NAMES, SUPPORTED_CHAIN_IDS } from "app-constants";
import { SupportedNetworks, WalletConnectorTypes } from "types";
import { Web3ReactContextInterface } from "@web3-react/core/dist/types";
import { SupportedChains } from "app-constants/conToken";
import Web3 from "web3";
import { useAppChain } from "hooks";
import { useLocation } from "react-router-dom";
import QueryString from "query-string";
import { isMobile } from 'react-device-detect';

interface UseWalletReturnType extends Web3ReactContextInterface {
  balance: string | number;
  connectWallet: (_type: WalletConnectorTypes) => Promise<void>;
  switchNetwork: (targetChain: SupportedNetworks) => Promise<void>;
  disconnectWallet: () => void;
}

const useWallet = (): UseWalletReturnType => {
  const {
    active,
    activate,
    chainId,
    error,
    account,
    library,
    connector,
    deactivate,
    ...web3React
  } = useWeb3React();
  const location = useLocation();
  const { chain } = QueryString.parse(location.search || "");
  const [openSnackbar] = useAppSnackbar();
  const [balance, setBalance] = useState<string | number>(0);

  const connectWallet = useCallback(
    async (type: WalletConnectorTypes) => {
      const walletConnector = typeof connector === 'function' ? connector : Connectors[type];
      try {
        // TODO need to check reconnect wallet after reopen our page.
        // if (typeof connector?.isAuthorized === "function") {
        //   await connector.isAuthorized();
        // }
        if (window.ethereum) {
          const web3 = new Web3(window.ethereum);
          try {
            const chainId = await web3.eth.getChainId();
            if (!SUPPORTED_CHAIN_IDS.includes(chainId)) {
              try {
                await window.ethereum.request({
                  method: "wallet_switchEthereumChain",
                  params: [{ chainId: Web3.utils.toHex(Number(chain)) }],
                });
              } catch (e: any) {
                if (e.code === 4902) {
                  console.error(
                    "This network is not available in your MetaMask, please add it manually",
                  );
                } else {
                  console.error("Failed to switch network:", e);
                }
              }
            }
            console.log(chainId);
          } catch (error) {
            console.error("Error fetching chain ID:", error);
          }
        } else {
          console.error("MetaMask not detected");
        }
        if(walletConnector) {
          await activate(walletConnector);
          localStorage.setItem(
            LOCAL_STORAGE_ITEM_NAMES.WALLET_TYPE,
            type.toString(),
          );
        }
        
      } catch (error) {
        console.error(error);
      }
    },
    [activate],
  );

  const switchBinanceWalletNetwork = useCallback(async (chainId: string) => {
    const iChainId = parseInt(chainId) as SupportedNetworks;
    await window.BinanceChain?.switchNetwork(BinanceWalletNetworkIds[iChainId]);
  }, []);
  const switchMetaMaskWalletNetwork = useCallback(
    async (chain: SupportedNetworks) => {
      try {
        await library.provider?.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3Utils.toHex(chain) }],
        });
      } catch (e: any) {
        if (e.code === 4902) {
          await addMetaMaskWalletNetwork(chain);
        } else {
          throw e.message;
        }
      }
    },
    [library?.provider],
  );

  const switchNetwork = useCallback(
    async (chain: SupportedNetworks) => {
      const savedType = localStorage.getItem(
        LOCAL_STORAGE_ITEM_NAMES.WALLET_TYPE,
      );
      let connectedType: WalletConnectorTypes;
      if (savedType) {
        connectedType = parseInt(savedType) as WalletConnectorTypes;
      } else {
        connectedType = WalletConnectorTypes.METAMASK;
      }

      const chainId = Web3Utils.toHex(chain);

      switch (connectedType) {
        // case WalletConnectorTypes.BINANCE_CHAIN: // works for binance wallet
        //   await switchBinanceWalletNetwork(chainId);
        //   break;

        default: // works for metamask and math walelt
          await switchMetaMaskWalletNetwork(chain);
          break;
      }
    },
    [switchBinanceWalletNetwork, switchMetaMaskWalletNetwork],
  );

  const addBinanceWalletNetwork = useCallback(async (chainId: string) => {
    return Promise.resolve();
  }, []);

  const addMetaMaskWalletNetwork = useCallback(
    async (chainId: SupportedNetworks) => {
      const appChain = SupportedChains.find(
        (chain) => chain.chainId === chainId,
      );
      if (appChain) {
        const networkParams = {
          chainId: Web3Utils.toHex(chainId),
          chainName: appChain.label, // The name of the custom Ethereum network
          nativeCurrency: {
            name: appChain.name,
            symbol: appChain.symbol,
            decimals: parseInt(appChain.decimals),
          },
          rpcUrls: appChain.rpcUrls,
          blockExplorerUrls: appChain.blockExplorerUrls, // Optional: The block explorer URL for the custom network
        };

        await library.provider?.request({
          method: "wallet_addEthereumChain",
          params: [networkParams],
        });
      }
    },
    [],
  );

  const disconnectWallet = useCallback(() => {
    try {
      deactivate();
      localStorage.removeItem(LOCAL_STORAGE_ITEM_NAMES.WALLET_TYPE);
    } catch (error: any) {
      console.error(error.message);
    }
  }, [deactivate]);

  // // refresh balance
  const refreshBaseTokenBalance = useCallback(() => {
    library
      ?.getBalance(account)
      .then((_balance: any) => {
        const balanceFromWei = Web3Utils.hexToNumberString(_balance._hex);
        setBalance(Web3Utils.fromWei(balanceFromWei));
      })
      .catch((err: any) => {
        console.error(err);
        setBalance(0);
      });
  }, [library, account]);

  useEffect(() => {
    if (error) {
      openSnackbar(error.message);
    }
    // disabled for  openSnackbar
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    if (account) {
      localStorage.setItem("account", account); // saving it for old functions
    } else {
      localStorage.removeItem("account"); // removing it for old functions
    }
  }, [account]);

  useEffect(() => {
    refreshBaseTokenBalance();
    library?.on("block", refreshBaseTokenBalance);
    return () => {
      library?.off("block", refreshBaseTokenBalance);
    };
  }, [library, refreshBaseTokenBalance]);

  return {
    active,
    error,
    account,
    library,
    chainId,
    balance,
    deactivate,
    ...web3React,
    connectWallet,
    switchNetwork,
    activate,
    disconnectWallet,
  };
};

export default useWallet;
