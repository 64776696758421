import Web3 from "web3";
import type { TransactionReceipt } from "web3-core";
import { isAddress, AbiItem } from "web3-utils";

import {
  BSwaprouter2ContractAddress,
  NETWORKS,
  REDEMPTION_LEFTOVER,
  ZERO_ADDRESS,
} from "app-constants";
import BondAbi from "Assets/Abi/bond-abi.json";
import DumperShieldABI from "Assets/Abi/DumperShieldABI.json";
import BondImplementationAbi from "Assets/Abi/bond-implementation-abi.json";
import ERC20Abi from "Assets/Abi/ERC20.json";
import { ActionBond, ActionTypes } from "./types";
import { createBond as addBondInformation } from "helper/apis/bond-server-api";
import Web3Helpers from "helper/Web3Helpers";
import fromExponential from "from-exponential";
import {
  decimalAmountToExactAmount,
  removeDecimalsInBigString,
  simpleAmountInString,
} from "helper/math";
import {
  coinGeckoApis,
  getCoin,
  getOfferTime,
  getTimeInSecs,
  isEqAddr,
} from "helper";
import { getAlterNativeToken } from "helper/bonds";
import { getBondContract, getBondMainContract } from "redux/actions";
import { RootState } from "redux/store";
import BN from "bn.js";

import {
  BondReducerParams,
  VestingPeriod,
  TokenParamsWithBalance,
  AppThunkAction,
  VestingForPrincipalParams,
  VestingForProfitParams,
  TokenBalance,
  CoinPrice,
  InvestmentLimitParams,
  StakingPeriodParams,
  BondTabs,
  FeeStructureLabelInfo,
  BaseToken,
} from "types";
import { Parameters, Rewarads } from "types/smart-contracts/ibo-proxy-contract";
import { createDumperShield } from "helper/sc-utils/DS-sc-utils";

type BondAppThunkAction<
  R, // Return type of the thunk function
  E // any "extra argument" injected into the thunk
> = AppThunkAction<R, RootState, E, ActionBond>;

const loadTokenInfo =
  (_token: string): BondAppThunkAction<Promise<TokenBalance>, null> =>
  async (dispatch, getState) => {
    const account = getState().globals.account;
    let newToken: TokenBalance = {
      name: "",
      symbol: "",
      address: "",
      decimals: "",
      balance: "0",
    };
    try {
      const web3 = new Web3(Web3.givenProvider);
      const w3Helper = new Web3Helpers(web3, account);
      const tokenContract = new web3.eth.Contract(
        ERC20Abi as AbiItem[],
        _token
      );
      const _symbol = await tokenContract.methods
        .symbol()
        .call()
        .catch((e: any) => e);
      const _name = await tokenContract.methods
        .name()
        .call()
        .catch((e: any) => e);
      const _decimals = await tokenContract.methods
        .decimals()
        .call((e: any) => e);
      const _balance = await w3Helper
        .getTokenBalance(_token)
        .catch((e: any) => e);
      const b = fromExponential(+_balance / 10 ** _decimals);
      newToken = {
        name: _name,
        symbol: _symbol,
        address: _token,
        decimals: _decimals,
        balance: b,
      };
      dispatch({
        type: ActionTypes.SET_TOKEN_INFO,
        payload: newToken,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: ActionTypes.SET_TOKEN_INFO,
        payload: newToken,
      });
    }
    return newToken;
  };

export const loadTokenRatio =
  (): BondAppThunkAction<Promise<any>, null> => async (dispatch, getState) => {
    const { amountOfSupply, raisingTarget, feeStructure } = getState().bond;
    const fee = +raisingTarget * (+feeStructure.fee / 100);

    dispatch({
      type: ActionTypes.SET_TOKEN_RATIO,
      payload: (+raisingTarget - fee) / +amountOfSupply,
    });
  };

export const setParams =
  (values: BondReducerParams): BondAppThunkAction<void, null> =>
  (dispatch) => {
    dispatch({
      type: ActionTypes.SET_PARAMETERS,
      payload: values,
    });
  };

export const setFeeStructure =
  (feeStructure: FeeStructureLabelInfo): BondAppThunkAction<void, null> =>
  (dispatch) => {
    dispatch({
      type: ActionTypes.SET_FEE_STRUCTURE,
      payload: feeStructure,
    });
    setTimeout(() => {
      dispatch(loadTokenRatio());
    }, 100);
  };

export const setParamsVestingForPrincipals =
  (values: VestingForPrincipalParams): BondAppThunkAction<void, null> =>
  (dispatch) => {
    dispatch({
      type: ActionTypes.SET_VESTING_FOR_PRINCIPAL,
      payload: values,
    });
  };

export const setPrepaymentPenalty =
  (enable = true): BondAppThunkAction<void, null> =>
  (dispatch) => {
    dispatch({
      type: ActionTypes.SET_PREPAYMENT_PENALTY,
      payload: enable,
    });
  };

export const setParamsVestingForProfit =
  (values: VestingForProfitParams): BondAppThunkAction<void, null> =>
  (dispatch) => {
    dispatch({
      type: ActionTypes.SET_VESTING_FOR_PROFIT,
      payload: values,
    });
  };

export const setParamsVestingPeriod =
  (values: VestingPeriod): BondAppThunkAction<void, null> =>
  (dispatch) => {
    dispatch({
      type: ActionTypes.SET_VESTING_PERIOD,
      payload: values,
    });
  };

export const setParamsInvestmentLimit =
  (values: InvestmentLimitParams): BondAppThunkAction<void, null> =>
  (dispatch) => {
    dispatch({
      type: ActionTypes.SET_INVESTMENT_LIMIT,
      payload: values,
    });
  };

export const setParamsStakingPeriod =
  (values: StakingPeriodParams): BondAppThunkAction<void, null> =>
  (dispatch) => {
    dispatch({
      type: ActionTypes.SET_STAKING_PERIOD,
      payload: values,
    });
  };

export const setHasProfitVesting =
  (state: boolean): BondAppThunkAction<void, null> =>
  (dispatch) => {
    dispatch({
      type: ActionTypes.SET_HAS_PROFIT_VESTING,
      payload: state,
    });
  };

export const setHasPrincipalVesting =
  (state: boolean): BondAppThunkAction<void, null> =>
  (dispatch) => {
    dispatch({
      type: ActionTypes.SET_HAS_PRINCIPAL_VESTING,
      payload: state,
    });
  };

export const nextStep =
  (): BondAppThunkAction<void, null> => (dispatch, getState) => {
    const tab = getState().bond.tab;

    dispatch({
      type: ActionTypes.SET_TAB,
      payload: tab + 1,
    });
  };

export const prevStep =
  (): BondAppThunkAction<void, null> => (dispatch, getState) => {
    const tab = getState().bond.tab;

    dispatch({
      type: ActionTypes.SET_TAB,
      payload: tab - 1,
    });
  };

export const setStep =
  (tabIndex: BondTabs): BondAppThunkAction<void, null> =>
  (dispatch) => {
    dispatch({
      type: ActionTypes.SET_TAB,
      payload: tabIndex,
    });
  };

export const setPairToken =
  ({
    name,
    symbol,
    address,
    decimals,
  }: {
    name: string;
    symbol: string;
    address: string;
    decimals: string;
  }): BondAppThunkAction<Promise<void>, null> =>
  async (dispatch, getState) => {
    const appChainId = getState().globals.appChainId;
    const account = getState().globals.account;
    const token = getState().bond.token;
    if (isEqAddr(token, address))
      throw new Error(
        "Waring!, Pair Token is the same. Please use different token"
      );
    const network = NETWORKS[appChainId];
    const w3 = new Web3(network.HTTP_PROVIDER_URL);
    const w3Helper = new Web3Helpers(w3, account);
    const balance = await w3Helper.getTokenBalance(
      isEqAddr(network.W_TOKEN_ADDRESS, address) ? ZERO_ADDRESS : address
    );
    const b = +balance / Math.pow(10, +decimals);
    dispatch({
      type: ActionTypes.SET_PAIR_TOKEN,
      payload: {
        name,
        symbol,
        address,
        decimals,
        balance: b.toString(),
      } as TokenParamsWithBalance,
    });
    const coin: CoinPrice | null | undefined = getCoin(address, symbol) as
      | CoinPrice
      | null
      | undefined;
    if (coin) {
      // update pair USD price
      coinGeckoApis
        .simplePrice({
          ids: coin.id,
          vs_currencies: "usd",
          include_market_cap: true,
          include_24hr_vol: true,
          include_24hr_change: true,
          include_last_updated_at: true,
        })
        .then((data) => {
          let price: number | string = 0;
          const values = Object.values(data);
          if (values.length === 0) price = 0;
          else {
            price = values[0].usd || 0;
          }
          dispatch({
            type: ActionTypes.SET_PAIRTOKEN_PRICE_USD,
            payload: price,
          });
        })
        .catch((error) => {
          console.log("ERROR while get Bond pair token USD price");
          console.log(error);
          dispatch({
            type: ActionTypes.SET_PAIRTOKEN_PRICE_USD,
            payload: 0,
          });
        });
    } else {
      dispatch({
        type: ActionTypes.SET_PAIRTOKEN_PRICE_USD,
        payload: 0,
      });
    }
  };

export const setToken =
  (_token: string): BondAppThunkAction<Promise<void>, null> =>
  async (dispatch) => {
    dispatch({
      type: ActionTypes.SET_TOKEN,
      payload: _token,
    });
    const emptyToken = {
      name: "",
      symbol: "",
      address: "",
      decimals: "",
      balance: "0",
    };
    try {
      if (isAddress(_token)) {
        const tokenInfo = await dispatch(loadTokenInfo(_token));
        if (+tokenInfo.balance === 0) {
          setTimeout(() => {
            dispatch({
              type: ActionTypes.SET_TOKEN,
              payload: "",
            });
          }, 2000);
          throw Error("No Token Balance on your wallet!");
        }
      } else {
        dispatch({
          type: ActionTypes.SET_TOKEN_INFO,
          payload: emptyToken,
        });
      }
    } catch (error: any) {
      dispatch({
        type: ActionTypes.SET_TOKEN_INFO,
        payload: emptyToken,
      });
      throw error;
    }
  };

/**
 *
 * @returns
 */
export const createBond =
  (): BondAppThunkAction<Promise<any>, null> => async (dispatch, getState) => {
    const web3 = new Web3(Web3.givenProvider);
    const currentBondContract = getBondContract(getState);
    const {
      token,
      // dex,
      startIBODate,
      endIBODate,
      investmentLimit,
      leftoverBurn,
      vestingForPrincipal,
      apyForUsers,
      vestingForProfit,
      dynamicPenalty,
      amountOfSupply,
      raisingTarget,
      shieldPeriod,
      dexWithPool,
      feeStructure,
      stakingPeriod,
      tokenInfo,
      pairToken,
    } = getState().bond;
    const { isStartProfit } = vestingForProfit;
    const { appChainId, account } = getState().globals;

    const targetAmount = simpleAmountInString(
      +raisingTarget,
      pairToken.decimals
    );
    const supplyAmount = simpleAmountInString(
      +amountOfSupply,
      tokenInfo.decimals
    );
    const minInvestment = simpleAmountInString(
      +investmentLimit.minimum,
      pairToken.decimals
    );
    const maxInvestment = simpleAmountInString(
      +investmentLimit.maximum,
      pairToken.decimals
    );

    const startDate = getTimeInSecs(getOfferTime(startIBODate, 10)); // should add 10 mins
    const endDate = getTimeInSecs(getOfferTime(endIBODate));

    const pToken = getAlterNativeToken(pairToken.address);
    const gradedPeriod: number =
      vestingForPrincipal.afterOption.value * +vestingForPrincipal.afterValue;
    let cliffProfitDate: number =
      vestingForProfit.afterOption.value * +vestingForProfit.afterValue;
    if (isStartProfit) {
      cliffProfitDate += gradedPeriod;
    }

    const parameters: Parameters = {
      token: token,
      pairToken: pToken || pairToken.address,
      // dexRouter: dex.router,
      startDate: startDate, // change millisec to second
      endDate: endDate, // change millisec to second
      leftoverBurn: leftoverBurn === REDEMPTION_LEFTOVER.BURN,
      vestingParams: {
        cliffDate: gradedPeriod,
        // gradedPeriod: gradedPeriod,
        // gradedPortionPercent: +vestingForPrincipal.portionValue * 1e2,
        cliffProfitDate: cliffProfitDate, //+vestingForProfit.afterValue * vestingForProfit.afterOption.value,
        // gradedProfitPeriod: +vestingForProfit.afterValue * vestingForProfit.afterOption.value,
        // todo need to check element for this property. we dont have ui element yet.
        // gradedProfitPortionPercent: +vestingForProfit.portionValue * 1e2, // change to decimals
        prepaymentPenalty: +dynamicPenalty * 1e2, // change to decimals
      },
      supplyAmount: supplyAmount,
      targetAmount: targetAmount,
      feeParams: {
        feeType: feeStructure.value,
        router: dexWithPool.router,
        dsReleaseTime: getTimeInSecs(shieldPeriod + 1000 * 60 * 30), // + 30 mins
        stakingPeriod: +stakingPeriod.value * stakingPeriod.timeOption.value,
        stakingAPY: +apyForUsers * 1e4, // change to 4 decimals
        licensee: ZERO_ADDRESS, // todo
      },
      minInvestment: minInvestment,
      maxInvestment: maxInvestment,
    };
    let value: string | undefined = undefined;
    const network = NETWORKS[appChainId];
    const oneToken = simpleAmountInString(1, +tokenInfo.decimals);
    // const price = (+targetAmount * +oneToken) / +supplyAmount;

    const sA = new BN(supplyAmount, 10);
    const tA = new BN(targetAmount, 10);
    const oT = new BN(oneToken, 10);

    const price = tA.mul(oT).div(sA);

    if (isEqAddr(pairToken.address, network.W_TOKEN_ADDRESS)) {
      value = price.toString(10);
    }

    const bondInstance = new web3.eth.Contract(
      BondAbi as AbiItem[],
      currentBondContract
    );
    const createBond = bondInstance.methods.createOffer(parameters);
    let gasPrice: string = await web3.eth.getGasPrice();
    gasPrice = removeDecimalsInBigString((+gasPrice * 1.1).toString());
    try {
      // to check params
      await createBond.estimateGas({ from: account, value: value });
      const result = await createBond.send({
        from: account,
        value: value,
        gasPrice: gasPrice,
      });
      return result;
    } catch (error: unknown) {
      // @ts-ignore
      const errorMessage: string = error.message || error.toString();
      if (errorMessage.search(/date error/gi) > -1) {
        throw new Error(
          "Make sure the time is correct according to your time zone.."
        );
      }
      throw error;
    }
  };

export const buyBond =
  (
    tokenAddr: string,
    decimals: string,
    classId: string,
    nonceId: string,
    payAmount: number
  ): BondAppThunkAction<Promise<any>, null> =>
  async (dispatch, getState) => {
    const { account, appChainId } = getState().globals;
    const { W_TOKEN_ADDRESS } = NETWORKS[appChainId];
    const currentBondContract = getBondContract(getState);

    const w3 = new Web3(Web3.givenProvider);
    let value = "";
    if (isEqAddr(tokenAddr, W_TOKEN_ADDRESS)) {
      value = simpleAmountInString(payAmount, +decimals);
    } else {
      // approve token to send in bond smart contract
      const w3Helper = new Web3Helpers(w3, account);
      const isAllowed = await w3Helper.findAllowedAmount(
        tokenAddr,
        payAmount,
        currentBondContract
      );
      if (!isAllowed) {
        await w3Helper.approveTokenTo(
          tokenAddr,
          decimals,
          payAmount,
          currentBondContract,
          account
        );
      }
    }
    const payAmountBN = simpleAmountInString(payAmount, +decimals);
    const bondInstance = new w3.eth.Contract(
      BondAbi as AbiItem[],
      currentBondContract
    );
    const method = bondInstance.methods.buyBond(classId, nonceId, payAmountBN);

    let gasPrice: string | number = await w3.eth.getGasPrice();
    gasPrice = removeDecimalsInBigString((+gasPrice * 1.1).toString());
    try {
      // to check params
      await method.estimateGas({ from: account, value: value });
      const result = await method.send({
        from: account,
        value: value,
        gasPrice: gasPrice,
      });
      console.log({ result });
      return result;
    } catch (error: unknown) {
      // @ts-ignore
      const errorMessage: string = error.message || error.toString();
      if (errorMessage.search(/IBO is not opened/gi) > -1) {
        throw new Error("IBO is not opend yet. please try after");
      } else if (errorMessage.search(/investment out of range/gi) > -1) {
        throw new Error("Investment out of range.");
      }
      throw error;
    }
  };

export const closeOffer =
  (
    classId: string,
    nonceId: string
  ): BondAppThunkAction<Promise<TransactionReceipt>, null> =>
  async (dispatch, getState) => {
    const { account } = getState().globals;
    const proxyBondContract = getBondContract(getState);
    const w3 = new Web3(Web3.givenProvider);
    const bondInstance = new w3.eth.Contract(
      BondAbi as AbiItem[],
      proxyBondContract
    );
    const method = bondInstance.methods.closeOffer(classId, nonceId);
    try {
      await method.estimateGas({ from: account });
      return (await method.send({ from: account })) as TransactionReceipt;
    } catch (error: unknown) {
      console.log(error);
      throw error;
    }
  };

export const addProjectInformation =
  (bondId: string): BondAppThunkAction<Promise<any>, null> =>
  async (dispatch, getState) => {
    const { logo, website, whitepaper } = getState().bond;
    const { appChainId } = getState().globals;
    dispatch({
      type: ActionTypes.IS_ADDING_PROJECT_INFO,
      payload: true,
    });
    const res = await addBondInformation({
      bondId: bondId,
      logo: logo.file as Blob,
      website: website,
      whitepaper: whitepaper,
      networkId: appChainId,
    });
    if (res.data.success) {
      dispatch({
        type: ActionTypes.IS_ADDING_PROJECT_INFO,
        payload: false,
      });
    } else {
      throw new Error("Something went wrong!. please try after 10 mins");
    }
  };

export const reset = (): BondAppThunkAction<void, null> => async (dispatch) => {
  dispatch({
    type: ActionTypes.RESET,
  });
};

export const getActiveSupply =
  (
    classId: string,
    nonceId: string
  ): BondAppThunkAction<Promise<string | number>, null> =>
  async (dispatch, getState) => {
    // returns activeSupply with decimals
    const currentBondContract = getBondMainContract(getState);
    const w3 = new Web3(Web3.givenProvider);
    const bondInstance = new w3.eth.Contract(
      BondImplementationAbi as AbiItem[],
      currentBondContract
    );
    const result = await bondInstance.methods
      .activeSupply(classId, nonceId)
      .call();
    return result || 0;
  };

// export const getTotalSupply = (classId: string, nonceId: string): BondAppThunkAction<Promise<any>, null> => async (dispatch, getState) => {
//   // returns totalSupply with decimals
//   const currentBondContract = getBondMainContract(getState)
//   const w3 = new Web3(Web3.givenProvider)
//   const bondInstance = new w3.eth.Contract(BondImplementationAbi as AbiItem[], currentBondContract)
//   const result = await bondInstance.methods.totalSupply(classId, nonceId).call()
//   return result || 0
// }

export const redeemBond =
  (
    classId: string,
    nonceId: string,
    amount: number | string
  ): BondAppThunkAction<Promise<any>, null> =>
  async (dispatch, getState) => {
    const currentBondContract = getBondMainContract(getState);
    const account = getState().globals.account;
    const w3 = new Web3(Web3.givenProvider);
    const bondInstance = new w3.eth.Contract(
      BondImplementationAbi as AbiItem[],
      currentBondContract
    );

    const transactions = [
      {
        classId,
        nonceId,
        amount,
      },
    ];

    const method = bondInstance.methods.redeem(account, transactions);
    // to check params
    await method.estimateGas({ from: account });
    const result = await method.send({ from: account });
    return result;
  };

export const withdrawProjectTokens =
  (
    classId: string,
    nonceId: string,
    amount: BN
  ): BondAppThunkAction<Promise<any>, null> =>
  async (dispatch, getState) => {
    const proxyBondContract = getBondContract(getState);
    const account = getState().globals.account;
    const w3 = new Web3(Web3.givenProvider);
    const bondInstance = new w3.eth.Contract(
      BondAbi as AbiItem[],
      proxyBondContract
    );
    const amt: string = fromExponential(amount.toString(10));
    const method = bondInstance.methods.withdrawProjectTokens(
      classId,
      nonceId,
      amt
    );
    try {
      await method.estimateGas({ from: account }); // to check params
      const result = await method.send({ from: account });
      return result;
    } catch (error: unknown) {
      // @ts-ignore
      const errorMessage: string = error.message || error.toString();
      if (errorMessage.search(/Not enough supply/gi) > -1) {
        throw new Error("Not enough supply on the bond.");
      }
      throw error;
    }
  };

export const addFarm =
  (
    classId: string,
    token: BaseToken,
    amount: string,
    period: number,
    toDumperShield: boolean,
    unlockDate: undefined | number = undefined
  ): BondAppThunkAction<Promise<TransactionReceipt>, null> =>
  async (dispatch, getState) => {
    const { account, appChainId } = getState().globals;
    const { W_TOKEN_ADDRESS } = NETWORKS[appChainId];
    let mainTokenValue: string | undefined = undefined;
    let isCoin = false;
    if (isEqAddr(token.address, W_TOKEN_ADDRESS)) {
      mainTokenValue = fromExponential(amount);
      isCoin = true;
    }
    const proxyBondContract = getBondContract(getState);
    const w3 = new Web3(Web3.givenProvider);
    if (!isCoin) {
      const w3Helpers = new Web3Helpers(w3, account);
      const exactAmount = decimalAmountToExactAmount(amount, token.decimals);
      await w3Helpers.smartApproveToken(
        token.address,
        token.decimals,
        exactAmount,
        proxyBondContract,
        account,
        W_TOKEN_ADDRESS
      );
    }
    const bondInstance = new w3.eth.Contract(
      BondAbi as AbiItem[],
      proxyBondContract
    );

    if (toDumperShield) {
      const DumperShieldContract = await bondInstance.methods
        .dumperShield()
        .call();
      const DSInstance = new w3.eth.Contract(
        DumperShieldABI as AbiItem[],
        DumperShieldContract
      );
      const shieldToken = await DSInstance.methods
        .dumperShieldTokens(token.address)
        .call();
      if (isEqAddr(shieldToken, ZERO_ADDRESS)) {
        await createDumperShield(
          appChainId,
          account,
          token.address,
          BSwaprouter2ContractAddress[appChainId],
          unlockDate as number
        );
      } else {
        console.log("dumperShieldTokens exists. continue addFarm...");
      }
    }
    const method = bondInstance.methods.addFarm(
      classId,
      token.address,
      amount,
      period,
      toDumperShield
    );
    try {
      await method.estimateGas({ from: account, value: mainTokenValue });
      const result = await method.send({
        from: account,
        value: mainTokenValue,
      });
      return result as TransactionReceipt;
    } catch (error: unknown) {
      // @ts-ignore
      const errorMessage: string = error.message || error.toString();
      console.log(error);
      if (errorMessage.search(/dumperShield error/gi) > -1) {
        throw new Error(
          "There is no token in Dumper Shield Token list. \n Try with another token or contact support to add your reward token."
        );
      }
      if (errorMessage.search(/max Farms added/gi) > -1) {
        throw new Error("Max Farms Added!.You can't add any more");
      }
      if (errorMessage.search(/No bonds/gi) > -1) {
        throw new Error("There is no bonds. you can't add farms");
      }
      if (errorMessage.search(/too small amount/gi) > -1) {
        throw new Error(
          "You entered too small amount to the farms. increase the amount!"
        );
      }
      throw error;
    }
  };

export const harvestFarms =
  (
    classId: string,
    farmIds: string[]
  ): BondAppThunkAction<Promise<TransactionReceipt>, null> =>
  async (dispatch, getState) => {
    const { account } = getState().globals;
    const proxyBondContract = getBondContract(getState);
    const w3 = new Web3(Web3.givenProvider);
    const bondInstance = new w3.eth.Contract(
      BondAbi as AbiItem[],
      proxyBondContract
    );
    const method = bondInstance.methods.harvestFarms(classId, farmIds);
    try {
      await method.estimateGas({ from: account });
      return (await method.send({ from: account })) as TransactionReceipt;
    } catch (error: unknown) {
      console.log(error);
      throw error;
    }
  };

export const harvest =
  (classIds: string[]): BondAppThunkAction<Promise<TransactionReceipt>, null> =>
  async (dispatch, getState) => {
    const { account } = getState().globals;
    const proxyBondContract = getBondContract(getState);
    const w3 = new Web3(Web3.givenProvider);
    const bondInstance = new w3.eth.Contract(
      BondAbi as AbiItem[],
      proxyBondContract
    );
    const method = bondInstance.methods.harvest(classIds);
    try {
      await method.estimateGas({ from: account });
      return (await method.send({ from: account })) as TransactionReceipt;
    } catch (error: unknown) {
      console.log(error);
      throw error;
    }
  };

export const getRewardsByClassIds =
  (
    classIds: string[],
    account: string
  ): BondAppThunkAction<Promise<Rewarads[]>, null> =>
  async (dispatch, getState) => {
    const proxyBondContract = getBondContract(getState);
    const w3 = new Web3(Web3.givenProvider);
    const bondInstance = new w3.eth.Contract(
      BondAbi as AbiItem[],
      proxyBondContract
    );
    const result = await bondInstance.methods
      .getRewards(classIds, account)
      .call();
    return result as Rewarads[];
  };
