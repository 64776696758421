import styled from "styled-components";
import type { StepType } from "@reactour/tour";
import AppCheckbox from "./core/elements/AppCheckbox";
import ReactDOM from "react-dom/client";
import { useEffect, useState } from "react";
import { VscChromeClose } from "react-icons/vsc";

const TourPopupWrap = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  top: 20px;
  left: 60%;
  z-index: 1000000;
  // transform: transalteX(-50%);

  & button {
    margin-left: 10px;
    color: white;
    background-color: #b92c1a;
    border-radius: 5px;
  }
`;

export function TourPopup({
  onClickClose,
  show,
}: {
  show: boolean;
  onClickClose: (state: boolean) => void;
}) {
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (show === false) {
      document.getElementById("tour-popup")?.classList.add("d-none");
    } else {
      document.getElementById("tour-popup")?.classList.remove("d-none");
    }
    const tour = ReactDOM.createRoot(document.getElementById("tour-popup")!);
    tour.render(
      <TourPopupWrap>
        <AppCheckbox
          id="tour-dont-show"
          checked={active}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setActive(e.target.checked)
          }
          label={
            <div className="text-muted">
              <label
                className="fs-md fw-bold text-danger"
                htmlFor="tour-dont-show"
              >
                Don&apos;t show again
              </label>
            </div>
          }
        />
        <button onClick={() => onClickClose(active)}>
          <VscChromeClose size={30} />
        </button>
      </TourPopupWrap>,
    );
  }, [active, show]);

  return null;
}

export const ControlButton = styled.button`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${(props) => props.theme.fontColors.muted};
  &:hover,
  &.active {
    color: ${({ theme }) => theme.colorPrimary};
  }
`;
const TourHeader = styled.div`
  font-size: ${({ theme }) => theme.fontSize["2md"]};
`;
const TourContent = styled.div`
  margin-top: 5px;
  font-size: ${({ theme }) => theme.fontSize.xxs};
`;

export const DerexTourSteps: StepType[] = [
  {
    selector: '[data-tour="derex-tour-sidebar"]',
    content: (
      <div className="tour-content">
        <TourHeader>Sidebar</TourHeader>
        <TourContent>Learn more about DEREX</TourContent>
      </div>
    ),
  },
  {
    selector: '[data-tour="derex-tour-circuit-breakers"]',
    content: (
      <div className="tour-content">
        <TourHeader>Circuit breakers</TourHeader>
        <TourContent>
          Limit slippage and maintain healthy trading. Liquidity providers can
          set five types of circuit breakers to prevent quick trades, limit
          dumping, halt trading during emergencies, and avoid rug pulling.
        </TourContent>
      </div>
    ),
    highlightedSelectors: ['[data-tour="derex-tour-detail-section"]'],
  },
  {
    selector: '[data-tour="derex-tour-secure-floor"]',
    content: (
      <div className="tour-content">
        <TourHeader>Secure floor</TourHeader>
        <TourContent>
          Protects LPs from dumping risk by setting a baseline ratio. If the
          token crashes, the Secure Floor triggers a circuit breaker. Traders
          also have protection that decreases with price appreciation. The
          Secure Floor protects against bank run scenarios.
        </TourContent>
      </div>
    ),
    highlightedSelectors: ['[data-tour="derex-tour-detail-section"]'],
  },
  {
    selector: '[data-tour="derex-tour-dynamic-fees"]',
    content: (
      <div className="tour-content">
        <TourHeader>Dynamic fees</TourHeader>
        <TourContent>
          LP can set fees for pools based on token volatility and market demand,
          which are adjusted in real-time using a Weighted Moving Average.
        </TourContent>
      </div>
    ),
    highlightedSelectors: ['[data-tour="derex-tour-detail-section"]'],
  },
  {
    selector: '[data-tour="derex-tour-dumpersheild-protection"]',
    content: (
      <div className="tour-content">
        <TourHeader>Dumper Shield</TourHeader>
        <TourContent>
          The world&apos;s first decentralized exchange for vesting tokens.{" "}
          <br />
          It allows project vesting tokens to access liquidity without affecting
          the token market price during the vesting period.
          <br />
          It utilizes a decentralized OTC and a liquidity Gateway to reduce
          selling pressure when the vesting period ends.
        </TourContent>
      </div>
    ),
    highlightedSelectors: ['[data-tour="derex-tour-detail-section"]'],
  },
  {
    selector: '[data-tour="derex-tour-transaction-setting"]',
    content: (
      <div className="tour-content">
        <TourHeader>Transaction settings</TourHeader>
        <TourContent>
          <p>Slippage:</p>
          <p>
            The percentage difference between the expected price of a trade and
            the actual executed price. This setting helps to prevent
            front-running or other types of price manipulation on the DEX.
          </p>
          <p>Transaction Speed:</p>
          <p>
            It&apos;s important to note that gas prices are dynamic and can
            change rapidly, depending on market conditions and network usage.
            Therefore, users should always monitor the current gas prices and
            adjust their transaction speed and gas price settings accordingly,
            to ensure that their transactions are processed quickly and
            efficiently.{" "}
          </p>
          <p>Transaction deadline:</p>
          <p>
            A transaction deadline is a setting that can be used to specify the
            maximum amount of time that a transaction should remain pending
            before it is canceled or replaced.
          </p>
          <p>Auto Router:</p>
          <p>
            Auto router is a feature that is commonly used in decentralized
            finance (DeFi) applications and platforms to automatically route
            transactions and trades to the most efficient and cost-effective
            liquidity pools or trading pairs.
          </p>
        </TourContent>
      </div>
    ),
  },
  {
    selector: '[data-tour="derex-tour-liquidity"]',
    content: (
      <div className="tour-content">
        <TourHeader>Liquidity</TourHeader>
        <TourContent>
          Adding or removing liquidity from pools whether the pool is new or
          existing. In the case of a new pool, the creator can also establish
          the regulatory rules for the pool.
        </TourContent>
      </div>
    ),
  },
  {
    selector: '[data-tour="derex-tour-tax"]',
    content: (
      <div className="tour-content">
        <TourHeader>Tax</TourHeader>
        <TourContent>
          DEREX offers a solution that allows any token to be converted into a
          native reflection token with a fixed or adjustable buy/sell tax paid
          in native tokens like BNB.
        </TourContent>
      </div>
    ),
  },
  {
    selector: '[data-tour="derex-tour-vote"]',
    content: (
      <div className="tour-content">
        <TourHeader>Vote</TourHeader>
        <TourContent>
          The voting area allows LP to propose new regulatory rules for the pool
          and participate in the voting process.
        </TourContent>
      </div>
    ),
  },
  {
    selector: '[data-tour="derex-tour-swap-arrow-02"]',
    content: (
      <div className="tour-content">
        <TourHeader>Swap arrow</TourHeader>
        <TourContent>
          Change the trading position by clicking on the arrow.
        </TourContent>
      </div>
    ),
    highlightedSelectors: [
      '[data-tour="derex-tour-swap-arrow-01"]',
      '[data-tour="derex-tour-swap-arrow-02"]',
    ],
  },
];

export const IBOTourSteps: StepType[] = [
  {
    selector: '[data-tour="ibo-tour-launch-free"]',
    content: (
      <div className="tour-content">
        <TourHeader>Launch for FREE</TourHeader>
        <TourContent>
          Set your intial bond offering terms to boost raise liquisty to your
          new pool
        </TourContent>
      </div>
    ),
  },
  {
    selector: '[data-tour="ibo-tour-secure-floor-protection"]',
    content: (
      <div className="tour-content">
        <TourHeader>Secure floor protection</TourHeader>
        <TourContent>
          The token baseline ratio protects LPs from dumping risk. If the token
          crashes, the Secure Floor triggers a circuit breaker.
        </TourContent>
      </div>
    ),
  },
  {
    selector: '[data-tour="ibo-tour-secure-discount"]',
    content: (
      <div className="tour-content">
        <TourHeader>Discount</TourHeader>
        <TourContent>
          Projects conducting public sales usually allocate 50% or more of funds
          as liquidity pool,leading to 75% discount costs. IBO method achieves
          the same outcome with only a 50% discount.
        </TourContent>
      </div>
    ),
  },
  {
    selector: '[data-tour="ibo-tour-investment-details"]',
    content: (
      <div className="tour-content">
        <TourHeader>Details</TourHeader>
        <TourContent>
          Click details for more investment info and terms
        </TourContent>
      </div>
    ),
    resizeObservables: ['[data-tour="ibo-tour-investment-details-parent"]'],
  },
  // {
  //   selector: '[data-tour="ibo-tour-twitter-promotion"]', // TODO
  //   content: (
  //     <div className='tour-content'>
  //       <TourHeader>Twitter promotion</TourHeader>
  //       <TourContent>
  //         Connect any IBO offering to your Twitter account
  //         to allow your followers to invest directly from
  //         Twitter and for you to gain an affiliate reward
  //       </TourContent>
  //     </div>
  //   ),
  // },
  // {
  //   selector: '[data-tour="ibo-tour-unlock-telegram"]',
  //   content: (
  //     <div className='tour-content'>
  //       <TourHeader>Unlock with connect to telegram</TourHeader>
  //       <TourContent>
  //         Join our Telegram channel to stay alert and
  //         discover hidden opportunities
  //       </TourContent>
  //     </div>
  //   ),
  //   highlightedSelectors: ['[data-tour="ibo-tour-unlock-telegram-section"]'],
  //   position: 'center'
  // },
  // {
  //   selector: '[data-tour="ibo-tour-become-an-affiliate"]', // TODO
  //   content: (
  //     <div className='tour-content'>
  //       <TourHeader>Become an affiliate </TourHeader>
  //       <TourContent>
  //         Spread the word to your followers and earn rewards
  //       </TourContent>
  //     </div>
  //   ),
  // },
];

export const LPInsureTourSteps: StepType[] = [
  {
    selector: '[data-tour="lpInsure-tour-sidebar"]',
    content: (
      <div className="tour-content">
        <TourHeader>Sidebar</TourHeader>
        <TourContent>Learn more about LP Insure</TourContent>
      </div>
    ),
  },
  {
    selector: '[data-tour="lpInsure-tour-deploy-lp-insurance"]',
    content: (
      <div className="tour-content">
        <TourHeader>Deploy LP Insurance</TourHeader>
        <TourContent>
          Set your insurance bond terms to boost your liquidity
        </TourContent>
      </div>
    ),
  },
  {
    selector: '[data-tour="lpInsure-tour-insure-bond-details"]',
    content: (
      <div className="tour-content">
        <TourHeader>Details</TourHeader>
        <TourContent>
          Click details for more investment info and terms
        </TourContent>
      </div>
    ),
  },
];
