import React, { useMemo, useState } from 'react'
import { FaLock, FaMediumM, FaRedditAlien, FaTelegramPlane, FaThumbsUp } from 'react-icons/fa'
import { TfiSharethis } from 'react-icons/tfi'
import { useParams } from 'react-router-dom'
import { IoLogoUsd, IoLogoGithub } from 'react-icons/io'
import { AiOutlineTwitter } from 'react-icons/ai'
import { BsDiscord, BsTwitter } from 'react-icons/bs'
import { GoGraph } from 'react-icons/go'
import Countdown from 'react-countdown'

import { BondInfoItem } from 'Component/core/elements/IBO-SharedComponents'
import { Divider } from 'Component/core/elements/Divider'
import { StatusIcon } from 'Component/core/elements/StatusIcon'
import * as S from './styled'
import { getExplorerUrl, getSiteName, getSupplyAmount, getTokenRatio, withZero } from 'helper'
import { Completionist, TokenBalanceSpan, ViewAddress } from 'Component/core/elements/others/global-components'
import AppTimeView from 'Component/core/elements/AppTimeView'
import { InvestInput } from 'Component/BondCards/elements'
import TokenSubgraph from 'Component/Charts/TokenSubgraph'
import { AppButton } from 'Component/core/elements/Buttons'
import AppSlider from 'Component/core/elements/Slider'
import { BondDetailSelector } from 'redux/selectors'
import { BlogExpandButton, BlogIcon, BlogItem, BlogItemContent, BlogItemHeader, BlogItemLink, BlogItemTimestamp, BlogItemTitle } from 'Component/core/elements/LP-Insure'
import CustomScrollbars from 'Component/core/elements/CustomScrollbars'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { fetchBond } from 'redux/reducers/bond-detail/actions'
import { useAppChain, usePriceBySymbol } from 'hooks'
import AppLoadingSpinner from 'Component/AppLoadingSpinner'
import * as bondActions from 'redux/reducers/bond-list/actions'
import useAppSnackbar, { AppWaringOptions } from 'hooks/useAppSnackbar'
import TokenIcon from 'Component/TokenIcon'
import useAppDebounceEffect from 'hooks/useAppDebounceEffect'
import { BondDetails, BondTimeCounter } from 'Component/BondCards/IBOBondCard'
import useIBOPoolParams from 'hooks/useIBOPoolParams'

type PageRouterParam = {
  bondId: string
}

function IBO_BondDetail() {
  const { bondId } = useParams<PageRouterParam>()
  const { appChainId } = useAppChain()
  const [openWarningSnackbar] = useAppSnackbar(AppWaringOptions)
  const { bond, isLoading } = useAppSelector(BondDetailSelector)
  const dispatch = useAppDispatch()

  const pairPriceUSD = 2 // usePriceBySymbol(bond?.paramerters.pairToken.symbol || '')
  const poolParams = useIBOPoolParams({ appChainId: appChainId, classId: bond?.classId || '', nonceId: bond?.nonceId || '' })
  const { totalRaisedPairTokens, spentTokens, fee } = poolParams

  const ratio = useMemo(() => {
    if (!bond) return 0;
    return getTokenRatio(bond.paramerters.token.address, [bond.poolParam.poolPair.token0, bond.poolParam.poolPair.token1], [bond.poolParam.poolPair.reserve0, bond.poolParam.poolPair.reserve1]);
  }, [bond])
  const { supplyAmount, availableSupplyAmount, supplyAmountBN } = useMemo(() => {
    if (!bond) return {
      supplyAmount: 0,
      availableSupplyAmount: 0,
      supplyAmountBN: 0
    }
    return getSupplyAmount(bond.paramerters.supplyAmount, bond.paramerters.token.decimals, fee, spentTokens)
  }, [bond, fee, spentTokens])

  const remainingAvailableAllocationInPercent: number = useMemo(() => {
    const value = (+supplyAmountBN - +spentTokens) / +supplyAmountBN
    return value * 100
  }, [supplyAmountBN, spentTokens])

  console.log('bond.paramerters.token.address: ', bond?.paramerters.token.address)
  console.log('bond.paramerters.pairToken.address: ', bond?.paramerters.pairToken.address)
  console.log('ratio: ', ratio)

  const explorer = useMemo<string>(() => {
    return getExplorerUrl(appChainId)
  }, [appChainId])

  const handleOnChangeInvestInput = () => {
    console.log('here')
  }
  const handleOnClickWithdraw = () => {
    console.log('here')
  }
  const handleOnClickMaxButton = () => {
    console.log('here')
  }
  const onClickInvest = () => {
    console.log('here')
  }

  const onClickVote = async () => {
    try {
      // @ts-ignore
      await dispatch(bondActions.voteBond(bond?.classId, bond?.nonceId))
    } catch (error: any) {
      console.error(error);
      openWarningSnackbar(error.message || String(error))
    }
  }

  useAppDebounceEffect(() => {
    dispatch(fetchBond(appChainId, bondId))

    // const _timer = setInterval(() => {
    //   dispatch(fetchBond(appChainId, bondId))
    // }, 30 * 1000)
    return () => {
      // clearInterval(_timer)
    }
  }, 400, [appChainId, bondId])

  const countdownComponent = useMemo(() => {
    return (
      <Countdown
        date={new Date(2023, 3, 20)}
        intervalDelay={0}
        precision={3}
        renderer={({ days, hours, minutes, seconds, completed, milliseconds }) => {
          const milli = milliseconds % 100
          const _days = withZero(days)
          const value = `${_days}:${withZero(hours)}:${withZero(minutes)}:${withZero(seconds)}:${withZero(milli)}`
          return completed ? <Completionist /> : <AppTimeView numclassName='p-5 px-7 fs-md' value={value} />
        }}
      />
    )
  }, [bond])

  if (isLoading) return <S.PageBox><AppLoadingSpinner /></S.PageBox>

  return (
    <S.PageBox>
      {
        bond === null
          ? <span>No Bond</span>
          : <React.Fragment>
            <S.DetailStatusDiv className='row'>
              <div className='col-8 d-flex align-items-center'>
                <StatusIcon className={`active mr-10`} />
                <span className='pool-id'>Live #{bond.viewId}</span>
              </div>
              <div className='col-4 d-flex justify-content-between pr-30 pl-15'>
                <div className='d-flex align-items-center'>
                  <S.DetailTitleSpan>Affiliate Program</S.DetailTitleSpan>
                  <Divider className='horizontal mx-10 highlighted-border-right h-80p' />
                  <S.DetailTitleSpan>Telegram Bot</S.DetailTitleSpan>
                </div>
                <div className='d-flex align-items-center'>
                  <TfiSharethis className='text-muted mx-20' size={25} />
                  <S.ReviewButton onClick={onClickVote}>
                    <FaThumbsUp className='mr-10' />
                    {/* @ts-ignore */}
                    <span>{parseInt(bond.info?.yeaVote || 0)}</span>
                  </S.ReviewButton>
                </div>
              </div>
            </S.DetailStatusDiv>
            <div className='row mt-30'>
              <div className='col highlighted-border-right:h-85p'>
                <S.ProjectDetailBox>
                  <div className='ProjectDetailBox-Info'>
                    <TokenIcon
                      className='rounded-circle bg-white'
                      src={bond.info?.logo}
                      byAddress={bond.paramerters.token.address}
                      alt={`Logo-${bond.paramerters.token.address}`}
                      style={{ width: 80, height: 80 }}
                    />
                    <div className='ProjectDetailBox-Info-Details'>
                      <S.ProjectSymbol>{bond.paramerters.token.symbol}</S.ProjectSymbol>
                      <S.ProjectName href='#' target='_blank' rel='noreferrer'>
                        {bond.paramerters.token.name}
                      </S.ProjectName>
                    </div>
                  </div>
                  <Divider className='horizontal px-0 highlighted-border-right' />
                  <div className='fs-14 ProjectDetailBox-Detail'>
                    <BondInfoItem icon={<IoLogoUsd />} label='Investment Limits' /*helpText={tooltip('Investment Limits')} */ />
                    <BondInfoItem icon={<FaLock size={13} />} label='Vesting Duration' nobg />
                  </div>
                </S.ProjectDetailBox>
              </div>
              <div className='col highlighted-border-right:h-82p px-30 fs-sm'>
                <S.ProjectDetailItem>
                  <span className='title'>Smart contract</span>
                  <a className='detail' href={`${explorer}/address/${bond.paramerters.token.address}`}
                    target='_blank'
                    rel='noreferrer'
                  >ibc/<ViewAddress address={bond.paramerters.token.address} start={3} end={6} /></a>
                </S.ProjectDetailItem>
                <S.ProjectDetailItem>
                  <span className='title'>Links</span>
                  <div className='detail'>
                    <a
                      href={`${bond.info?.website}`}
                      target='_blank'
                      rel='noreferrer'
                      className='text-primary'
                    >{bond.info?.website ? getSiteName(bond.info?.website) : 'No site url'}</a>
                    <Divider className='horizontal mx-5 highlighted-border-right h-80p' />
                    <a
                      href={`${bond.info?.whitepaper}`}
                      target='_blank'
                      rel='noreferrer'
                      className='text-primary'
                    >{bond.info?.whitepaper ? getSiteName(bond.info?.whitepaper) : 'No Whitepaper'}</a>
                  </div>
                </S.ProjectDetailItem>
                <S.ProjectDetailItem>
                  <span className='title'>Explorers</span>
                  <div className='detail'>
                    <span>{getSiteName(explorer)}</span>
                  </div>
                </S.ProjectDetailItem>
                <S.ProjectDetailItem>
                  <span className='title'>Community</span>
                  <div className='detail'>
                    <FaRedditAlien />
                    <Divider className='horizontal mx-5 highlighted-border-right h-80p' />
                    <AiOutlineTwitter />
                    <Divider className='horizontal mx-5 highlighted-border-right h-80p' />
                    <FaTelegramPlane />
                    <Divider className='horizontal mx-5 highlighted-border-right h-80p' />
                    <BsDiscord />
                    <Divider className='horizontal mx-5 highlighted-border-right h-80p' />
                    <FaMediumM />
                  </div>
                </S.ProjectDetailItem>
                <S.ProjectDetailItem>
                  <span className='title'>Source Code</span>
                  <div className='detail'>
                    <IoLogoGithub />
                  </div>
                </S.ProjectDetailItem>
              </div>
              <div className='col px-30'>
                <div className='d-flex align-items-center justify-content-between fs-sm'>
                  <BondTimeCounter
                    startDate={new Date(bond.paramerters.startDate)}
                    endDate={new Date(bond.paramerters.endDate)}
                    numSize='sm'
                    flow='column'
                  />
                </div>
                <div className='d-flex align-items-center justify-content-between mt-20 fs-sm'>
                  <h5 className='mb-0 text-muted'>1 {bond.paramerters.token.symbol} = ${ratio * pairPriceUSD}, 1 {bond.paramerters.pairToken.symbol} = <TokenBalanceSpan prefix='$' digits={6} >{pairPriceUSD}</TokenBalanceSpan></h5>
                  <GoGraph className='text-primary fw-bold' />
                </div>
                <InvestInput
                  className='mt-20'
                  tokenAddress={'0x0000000'}
                  valueInUsd={0.22}
                  isInvalid={false}
                  isLoading={false}
                  max={{
                    symbol: 'PDO',
                    value: 1000,
                  }}
                  value={10}
                  hideWithdraw={true}
                  onChangeInvestInput={handleOnChangeInvestInput}
                  onClickWithdraw={handleOnClickWithdraw}
                  onClickMax={handleOnClickMaxButton}
                  onClickInvest={onClickInvest}
                />
                <Divider className='vertical mx-5 highlighted-border-bottom' />
                <div className='mt-10'>
                  <div className='d-flex text-muted fs-sm'>
                    <span className='ml-10'>Received</span>
                    <div className='ml-10'>
                      <p className='mb-0'>
                        <TokenBalanceSpan>1234567890</TokenBalanceSpan>
                      </p>
                      <div>
                        <span>Extra&nbsp;</span>
                        <TokenBalanceSpan>1234567890</TokenBalanceSpan>
                        <span>&nbsp;|&nbsp;</span>
                        <span>-2.5% cheaper vs DEX</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <S.GraphAndDetail className='row my-20'>
              <div className='col-8 highlighted-border-right'>
                <TokenSubgraph
                  token={{
                    address: bond.paramerters.token.address,
                    name: bond.paramerters.token.name,
                    symbol: bond.paramerters.token.symbol
                  }}
                />
              </div>
              <div className='col-4 text-muted fs-sm'>
                <BondDetails bond={bond} pairPriceUSD={pairPriceUSD} />
              </div>
            </S.GraphAndDetail>
            {/* TODO */}
            <p className='text-muted fs-sm'>PDO finance is an open-source sharded multichain protocol that connects and secures a network of specialized blockchains, facilitating cross-chain transfer of any data or asset types, not just tokens, thereby allowing blockchains to be interoperable with each other. Polkadot was designed to provide a foundation for a decentralized internet of blockchains, also known as Web3.</p>
            {/* TODO */}
            <AppButton className='no-fill-primary fs-sm w-auto pl-0 fw-light'>Read More</AppButton>
            <div className='mt-20'>
              <p className='text-center text-primary-2 fs-sm mb-5'>Total raised {remainingAvailableAllocationInPercent.toFixed(2)}%</p>
              {/* @ts-ignore */}
              <AppSlider className='primary-1 mb-10' readOnly value={(100 - remainingAvailableAllocationInPercent).toFixed(2)} />
              <AppSlider className='primary-1' value={remainingAvailableAllocationInPercent} />
              <p className='text-primary text-center fs-sm mt-20'>Remaining available allocation {remainingAvailableAllocationInPercent.toFixed(2)}%</p>
            </div>
            <Divider className='vertical mt-40 highlighted-border-bottom' />
            <S.LPInsurePoolDetailBox>
              <div className='Bond-Twitter-Section highlighted-border-right'>
                <div className='d-flex align-items-center justify-content-between'>
                  <S.SocialCategoryTitle>Tweets</S.SocialCategoryTitle>
                  <BsTwitter className='SocialCategory-Icon' />
                </div>
                <div>
                  <CustomScrollbars className='dark-1 scrollbar-show' height='320px' width='100%' style={{ position: 'relative' }}>
                    <div className='pr-40'>
                      {new Array(20).fill(10).map((v, i) => <BlogItem key={i}>
                        <BlogItemHeader>
                          <BlogIcon src='/images/pdo-med.png' />
                          <div className='w-full ml-20'>
                            <BlogItemTitle>
                              PDO
                            </BlogItemTitle>
                            <BlogItemLink>
                              @PDO
                            </BlogItemLink>
                          </div>
                          <BlogItemTimestamp>
                            4h
                          </BlogItemTimestamp>
                        </BlogItemHeader>
                        <BlogItemContent>
                          Introducing Unbounded, the first open source, freely available and on-chain funded font in the world.
                        </BlogItemContent>
                        <BlogExpandButton>Expand</BlogExpandButton>
                        <Divider className='vertical my-15 highlighted-border-bottom' />
                      </BlogItem>)}
                    </div>
                  </CustomScrollbars>
                </div>
                <S.TweetButton>Tweet to @pdofinance</S.TweetButton>
              </div>
              <div className='Bond-Telegram-Section highlighted-border-right'>
                <div className='d-flex align-items-center justify-content-between'>
                  <S.SocialCategoryTitle>Telegram</S.SocialCategoryTitle>
                  <FaTelegramPlane className='SocialCategory-Icon' />
                </div>
                <div>
                  <CustomScrollbars className='dark-1 scrollbar-show' height='320px' width='100%' style={{ position: 'relative' }}>
                    <div className='pr-40'>
                      {
                        new Array(20).fill(10).map((v, i) => <BlogItem key={i}>
                          <BlogItemHeader>
                            <BlogIcon src='/images/pdo-med.png' />
                            <div className='w-full ml-20'>
                              <BlogItemTitle>
                                PDO
                              </BlogItemTitle>
                              <BlogItemLink>
                                @PDO
                              </BlogItemLink>
                            </div>
                            <BlogItemTimestamp>
                              4h
                            </BlogItemTimestamp>
                          </BlogItemHeader>
                          <BlogItemContent>
                            Interested in building on Polkadot? In 20 minutes now, tune in to the Twitter Spaces on @PolkadotEspanol to learn all about the upcoming Polkadot hackathon in Latin America: application process...
                          </BlogItemContent>
                          <Divider className='vertical my-15 highlighted-border-bottom' />
                        </BlogItem>)
                      }
                    </div>
                  </CustomScrollbars>
                </div>
              </div>
              <div className='Bond-News-Section'>
                <div className='d-flex align-items-center justify-content-between'>
                  <S.SocialCategoryTitle>News &amp; Media</S.SocialCategoryTitle>
                  <div>
                    <span className='SocialCategory-Icon fw-bold'>Google&nbsp;</span>
                    <span className='SocialCategory-Icon'>News</span>
                  </div>
                </div>
                <div>
                  {
                    new Array(2).fill(10).map((v, i) => <BlogItem key={i}>
                      <BlogItemHeader>
                        <div className='w-full pr-20'>
                          <BlogItemContent className='text-primary'>PDO Finance investors should be aware of these updates before pulling out</BlogItemContent>
                        </div>
                        <BlogIcon src='/images/pdo-med.png' />
                      </BlogItemHeader>
                      <BlogItemContent className='mt-10'>
                        Introducing Unbounded, the first open source, freely available and on-chain funded font in the world.
                      </BlogItemContent>
                      <div>
                        <BlogItemTimestamp>4h ago</BlogItemTimestamp>
                      </div>
                      <Divider className='vertical my-15 highlighted-border-bottom' />
                    </BlogItem>)
                  }
                </div>
              </div>
            </S.LPInsurePoolDetailBox>
            <Divider className='vertical highlighted-border-bottom' />
          </React.Fragment>
      }
    </S.PageBox>
  )
}

export default IBO_BondDetail
