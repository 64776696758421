import { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Modal, { PopInput01, PPstitle02 } from '../Modal'
import Media from '../../Theme/media-breackpoint'
import { IoIosClose } from 'react-icons/io'

import { SupportedChains } from 'app-constants/conToken'
import PPTokenListItem from './PPTokenListItem'

import { useAppChain, useAvailableTokens, useOnScreen, useSimplePrice } from '../../hooks/index'
import ManageToken from './ManageToken'
import { EthCommonTokenSymbols, BnBCommonTokenSymbols } from 'app-constants'
import CustomScrollbars from 'Component/core/elements/CustomScrollbars'
import { useCommonTokens } from 'hooks/useCommonTokens'
// @ts-ignore
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import TokenIcon from 'Component/TokenIcon'
import { PPClosBTN01 } from 'Component/core/elements/Buttons'
import { FlexDiv, ModalBox } from 'Component/core/elements/others/global-components'
import TokenSubgraph from 'Component/Charts/TokenSubgraph'
import { AppSupportedChain, AppToken, BaseToken, KeyValuePair, TokenBalance } from 'types'
import { useCustomTokens } from 'hooks/useCustomTokens'
import AppTooltip from 'Component/AppTooltip'
import SelectNetwork from './SelectNetwork'
import useAppSnackbar, { AppWaringOptions } from 'hooks/useAppSnackbar'
import AppLoadingSpinner from 'Component/AppLoadingSpinner'
import { useAppSelector } from 'redux/hooks'
import { GlobalsSelector } from 'redux/selectors'
import useAppDebounceEffect from 'hooks/useAppDebounceEffect'

enum TAB_INDEXS {
  SELECT_TOKEN = 0,
  MANAGE_TOKEN = 1,
}

interface ISelectTokenPopupProps {
  onSelectToken: ({ name, symbol, address, decimals }: { name: string, symbol: string, address: string, decimals: string }) => void
  defaultToken?: string
  disabledAddresses?: string[]
  showOnlyPoolTokens?: boolean
  onlyTokensWithBalance?: boolean
  specialSelectToken?: (from: AppToken, to: AppToken) => void
  isOpen: boolean
  dismiss: () => void
}

export default function SelectTokenPopup({
  defaultToken = '',
  disabledAddresses = [],
  showOnlyPoolTokens = false,
  onlyTokensWithBalance = false,
  specialSelectToken,
  ...props
}: ISelectTokenPopupProps) {
  const { appChainId, switchAppNetwork } = useAppChain()
  const { userTokensWithBalance } = useAppSelector(GlobalsSelector)

  const tokenPrices = useSimplePrice()
  const [openWSnack] = useAppSnackbar(AppWaringOptions)
  const [tabIndex, setTabIndex] = useState(TAB_INDEXS.SELECT_TOKEN)
  const [tokenList, setTokenList] = useState<BaseToken[] | TokenBalance[]>([])
  const [selectedTokenA, setSelectedTokenA] = useState({
    name: '',
    symbol: '',
    address: '',
  })
  const [searchValue, setSearchValue] = useState('')
  const [visible, lastRef] = useOnScreen({ threshold: 0.1 })
  const [showNetworkPopup, setShowNetworkPopup] = useState(false)
  const [availableTokens, allTokens] = useAvailableTokens()
  const { tokens: customTokens } = useCustomTokens(appChainId)
  const [fromChain, setFromChain] = useState<AppSupportedChain>(SupportedChains[0]) // 'ETH' | 'BSC'
  const { commonBases, add: addToCommon, remove: removeFromCommon } = useCommonTokens()

  const tokens = useMemo<BaseToken[] | TokenBalance[]>(() => {
    let tts: BaseToken[] | TokenBalance[];
    if (onlyTokensWithBalance && showOnlyPoolTokens) {
      const availableAddresses: KeyValuePair<boolean> = {}
      availableTokens.forEach((aToken) => {
        availableAddresses[`${aToken.address}`.toLowerCase()] = true;
      })
      tts = userTokensWithBalance.tokens.filter((bToken) => {
        return availableAddresses[`${bToken.address}`.toLowerCase()]
      })
    } else if (onlyTokensWithBalance) {
      tts = userTokensWithBalance.tokens;
    } else if (showOnlyPoolTokens) {
      tts = availableTokens;
    } else {
      tts = allTokens.concat(customTokens)
    }

    // sort tokens
    return tts.sort((a) => {
      return a.symbol && a.symbol.search(/bnb|eth|tron|matic/gi) > -1 ? -1 : 1
    })
  }, [allTokens, availableTokens, showOnlyPoolTokens, onlyTokensWithBalance, customTokens, userTokensWithBalance])

  const hasCommonTokens = useMemo(() => {
    const _tokens: KeyValuePair<boolean> = {}
    commonBases.forEach(base => {
      _tokens[base.toLowerCase()] = true;
    })
    return _tokens
  }, [commonBases])

  const commonTokens = useMemo(() => {
    return tokens.filter((t) => commonBases.includes(t.address))
  }, [commonBases, tokens])

  const removeFromCommonTokens = useCallback(
    (e: any, token: BaseToken) => {
      e.stopPropagation()
      // @ts-ignore
      removeFromCommon(token.value || token.address)
    },
    [removeFromCommon]
  )

  const addTokenToCommonTokens = useCallback(
    (e: any, token: BaseToken) => {
      e.stopPropagation()
      addToCommon(token.address)
    },
    [addToCommon]
  )

  const handleSelectToken = useCallback((e: any, _token: BaseToken) => {
    setSelectedTokenA(_token)
  }, [])

  // init common list
  useEffect(() => {
    const commonSymbols = [...EthCommonTokenSymbols, ...BnBCommonTokenSymbols]
    const initialCommonTokens = tokens.filter((e) => commonSymbols.includes(e.symbol))
    const addresses = initialCommonTokens.map((t) => t.address)
    addToCommon(addresses)
  }, [tokens, addToCommon])

  useEffect(() => {
    if (tokenList?.length && defaultToken) {
      const tokens = defaultToken.substring(1)
      const [from, to] = tokens.split('-')
      if (typeof specialSelectToken === 'function') {
        specialSelectToken(
          // @ts-ignore Not sure need to check
          (tokens || []).find((e) => e.symbol.toUpperCase() === (from || '').toUpperCase()) as AppToken,
          // @ts-ignore Not sure need to check
          (tokens || []).find((e) => e.symbol.toUpperCase() === (to || '').toUpperCase()) as AppToken
        )
      }
    }
  }, [defaultToken, tokens, tokenList?.length, specialSelectToken])

  // infinite scroll to show tokens
  useAppDebounceEffect(() => {
    if (visible) {
      const _tokens = tokens.slice()
      setTokenList((s) => _tokens.slice(0, s.length + 30))
    }
  }, 300, [tokens, visible])

  useEffect(() => {
    if (!props.isOpen) {
      setSearchValue('')
    }
  }, [props.isOpen])

  // reset from chain when popup is reopen
  useAppDebounceEffect(() => {
    if (!props.isOpen) {
      const activedChain = SupportedChains.find(s => s.chainId === appChainId)
      // @ts-ignore
      setFromChain(activedChain)
    }
  }, 300, [props.isOpen, appChainId])

  // search tokens
  useAppDebounceEffect(() => {
    if (searchValue) {
      const regex = new RegExp(searchValue, 'gi');
      const filteredTokens = tokens.filter((e) => e.symbol.search(regex) > -1 || e.address.search(regex) > -1).slice(0, 30);
      setTokenList(filteredTokens)
    } else {
      setTokenList(tokens.slice(0, 30))
    }
  }, 300, [searchValue, customTokens, tokens])

  useAppDebounceEffect(() => {
    if (userTokensWithBalance.errorMessage) {
      openWSnack(userTokensWithBalance.errorMessage)
    }
  }, 300, [userTokensWithBalance.errorMessage])

  const alternativeCommonTokens = useMemo(() => commonTokens, [commonTokens]);

  const coinListElt = useMemo(() => {
    return (Array.isArray(tokenList) ? tokenList : []).map((item: BaseToken | TokenBalance, index) => {
      const alphaSymbol = `${item.symbol}`.replace(/\W+/g, '');
      const reg = new RegExp(alphaSymbol, 'i')
      let tSymbol = ''
      const coin = tokenPrices.find((t) => {
        tSymbol = t.symbol.replace(/\W+/g, '');
        return tSymbol.search(reg) > -1
      })
      const changeIn24 = Number(coin?.usd_24h_change || 0).toFixed(2);

      return (
        <PPTokenListItem
          className={item.address === selectedTokenA.address ? 'active' : ''}
          key={'token-item-' + index}
          symbol={item.symbol}
          tokenAddress={item.address}
          // @ts-ignore
          balance={item.balance ?? ''}
          onDoubleClick={() => {
            props.onSelectToken(item)
          }}
          onClick={(e) => handleSelectToken(e, item)}
          pined={hasCommonTokens[`${item.address}`.toLowerCase()]}
          onClickPined={(e) => removeFromCommonTokens(e, item)}
          onClickRemovePin={(e) => addTokenToCommonTokens(e, item)}
          // @ts-ignore
          disabled={disabledAddresses.includes(item.address)}
          changeIn24={+changeIn24}
        />
      )
    })
  }, [disabledAddresses, tokenList, tokenPrices, selectedTokenA.address, hasCommonTokens, addTokenToCommonTokens, removeFromCommonTokens, handleSelectToken, props.onSelectToken])

  return (
    <Modal isOpen={props.isOpen} dismiss={props.dismiss}>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(index: number) => {
          setTabIndex(index)
        }}
      >

        <TabList className="d-none">
          <Tab></Tab>
          <Tab></Tab>
        </TabList>
        <TabPanel>
          <PPMainBx>
            <PPsBx01 className='position-relative'>
              <PPClosBTN01 className='position-absolute right-10 top-10' onClick={props.dismiss} />

              <PPstitle01 className='d-flex justify-content-between align-items-center'>
                <h4>
                  Select <i className='fas helpIco fa-question-circle' data-type='light' data-html='true' data-class='data-tooltip' data-tip='Type in a token name or smart contract address'></i>
                </h4>
                <div className='d-flex'>
                  <ChainButton onClick={() => setShowNetworkPopup(true)}>{fromChain.name}</ChainButton>
                </div>
              </PPstitle01>
              <PopInput01 onChange={(e) => setSearchValue(e.target.value)} value={searchValue} placeholder='Search name or past address' />
              <PPselectTokenBTN>
                {[...alternativeCommonTokens].map((item, index) => (
                  <button
                    key={'common-token-' + index + '-' + item.address}
                    disabled={disabledAddresses.includes(item.address)}
                    className={selectedTokenA.address === item.address ? 'active' : ''}
                    onClick={(e) => {
                      handleSelectToken(e, item)
                    }}
                    onDoubleClick={() =>
                      setTimeout(() => {
                        props.onSelectToken(item)
                      }, 1)
                    }
                  >
                    <ImgBx>
                      {/* @ts-ignore */}
                      <TokenIcon byAddress={item.address} alt={'image - ' + item.name} width='50' height='50' />
                    </ImgBx>
                    <span>{item.symbol}</span>
                    <IoIosClose onClick={(e) => removeFromCommonTokens(e, item)} className='top-right-icon' />
                  </button>
                ))}
              </PPselectTokenBTN>
            </PPsBx01>
            <div className='row'>
              <div className='col'>
                <PPstitle02 className='pl-30'>
                  <span>Token name</span>

                  <div className='d-flex'>
                    <button className='ml-10' hidden={!onlyTokensWithBalance}>
                      <span>Balance</span>
                    </button>
                    <button className='ml-10'>
                      <span className='mr-10'>24h change(%)</span>
                      <i className='fas fa-arrow-down'></i>
                    </button>
                  </div>
                </PPstitle02>
                <CustomScrollbars height='320px' width='100%' style={{ position: 'relative' }} scrollDirection='left' trackClass='black'
                  renderView={({ style, ...props }) => <div style={{ ...style, marginBottom: 0, marginRight: 0 }} {...props} className='view scrollbar-none' />}
                >
                  {
                    userTokensWithBalance.isLoading
                      ? <AppLoadingSpinner className='h-full d-flex align-items-center justify-content-center' />
                      : (
                        <div className='pb-20'>
                          {coinListElt}
                        </div>
                      )
                  }
                  {searchValue ? <></> : <div ref={lastRef} />}
                </CustomScrollbars>
              </div>
              <div className='col p-20'>
                {selectedTokenA.symbol === '' ? (
                  <div className='w-full h-full d-flex justify-content-center align-items-center'>Please select token</div>
                ) : (
                  <TokenSubgraph
                    token={{
                      name: selectedTokenA.symbol,
                      symbol: selectedTokenA.symbol,
                      address: selectedTokenA.address,
                    }}
                  />
                )}
              </div>
            </div>
            <PPsBx02>
              <button className='fs-md' onClick={() => setTabIndex(TAB_INDEXS.MANAGE_TOKEN)}>
                Having trouble finding a token?
              </button>
            </PPsBx02>
          </PPMainBx>
        </TabPanel>
        <TabPanel>
          <ManageToken partialDismiss={() => setTabIndex(TAB_INDEXS.SELECT_TOKEN)} dismiss={() => setTabIndex(TAB_INDEXS.SELECT_TOKEN)} />
        </TabPanel>
      </Tabs>
      <AppTooltip />
      <SelectNetwork isOpen={showNetworkPopup} onDismiss={() => setShowNetworkPopup(false)} />
    </Modal>
  )
}

const PPMainBx = styled(ModalBox)`
  max-width: 900px;
  min-width: 900px;
  margin: 0 auto;
  ${Media.lg} {
    min-width: 900px;
  }
  ${Media.md} {
    min-width: auto;
  }
  ${Media.xs} {
    width: auto;
    margin: 0px 15px;
    max-width: 100%;
  }
`

const PPsBx01 = styled(FlexDiv)`
  width: 100%;
  padding: 30px 30px 10px 30px;
`

const PPsBx02 = styled(FlexDiv)`
  width: 100%;
  padding: 20px 30px 20px 30px;
  border-top: #3c3f46 1px solid;

  & button {
    border: 0px;
    outline: none;
    background-color: transparent;
    color: white;

    :hover {
      opacity: 0.7;
      color: #8be05a;
    }
  }
`

const PPselectTokenBTN = styled(FlexDiv)`
  justify-content: flex-start;
  width: 100%;
  padding: 8px 0;

  button {
    display: inline-flex;
    color: #fff;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 10px;
    margin: 0 8px 8px 0;
    position: relative;
    border-radius: 5px;
    border: 1px solid grey;

    :hover {
      background-color: ${({ theme }) => theme.colorPrimary};
      .top-right-icon {
        display: block;
      }
    }

    &.active {
      background-color: ${({ theme }) => theme.colorPrimary};
    }

    &:disabled,
    &[disabled] {
      color: gray;
      cursor: not-allowed;
    }
  }
`

const PPstitle01 = styled(FlexDiv)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 0 12px 0;
  position: relative;
  color: white;
`

const ImgBx = styled(FlexDiv)`
  width: 23px;
  height: 23px;
  text-align: center;
  margin-right: 5px;
  border-radius: 15px;
  overflow: hidden;

  img {
    max-width: 100%;
    height: auto;
  }
`

const ChainButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 90px;

  background-color: ${({ theme }) => theme.bodybg01};
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.greyBorder};
  padding: 6px 10px;
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.colorWhite};
`
