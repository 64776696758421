import React, { useState, useEffect, useMemo } from "react";
import { IoLogoUsd } from "react-icons/io";
import {
  FaExternalLinkAlt,
  FaThumbsUp,
  FaPercentage,
  FaLock,
} from "react-icons/fa";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import Countdown from "react-countdown";
import { AiOutlineTwitter } from "react-icons/ai";
import fromExponential from "from-exponential";
import { BsQuestionCircleFill } from "react-icons/bs";
import { FontSize } from "styled-components";

import * as bondActions from "redux/reducers/bond-list/actions";
import { AppButton } from "Component/core/elements/Buttons";
import {
  AmountLabel,
  Box,
  Completionist,
  TokenBalanceSpan,
  ViewAddress,
} from "Component/core/elements/others/global-components";
import AppSlider from "Component/core/elements/Slider";
import { StatusIcon } from "Component/core/elements/StatusIcon";
import { Divider } from "Component/core/elements/Divider";
import { DetailItem, InvestInput } from "./elements";
import {
  getRevertedTokenRatio,
  getSupplyAmount,
  getTokenRatio,
  isEqAddr,
  numberFormater,
  withZero,
} from "helper";
import AppTimeView from "Component/core/elements/AppTimeView";
import tooltip from "app-constants/tooltip-texts";
import { NETWORKS, ZERO_ADDRESS } from "app-constants";
// import { secondsToLabel } from 'helper/dynamicHelpers'
import {
  useAppChain,
  useFailedInfoModal,
  usePriceBySymbol,
  useProcessingModal,
  useTransactionModal,
  useWeb3,
} from "hooks";
import AppTooltip from "Component/AppTooltip";
import * as S from "./styled";
import {
  buyBond,
  withdrawProjectTokens,
  closeOffer,
} from "redux/reducers/bond/actions";
import useAppSnackbar, { AppWaringOptions } from "hooks/useAppSnackbar";
import useWallet from "hooks/useWallet";
import TokenIcon from "Component/TokenIcon";
import Web3Helpers from "helper/Web3Helpers";
import { decimalAmountToExactAmount } from "helper/math";
import { useAppDispatch } from "redux/hooks";
import { BondData } from "types";
import { BondInfoItem } from "Component/core/elements/IBO-SharedComponents";
import AppLink from "Component/AppLink";
import { AppRouters } from "app-constants/app-routers";
import BSwapPairHelper from "helper/subgraphs/LPHelpers";
import useAppDebounceEffect from "hooks/useAppDebounceEffect";
import { getPoolParams } from "helper/sc-utils/ibo-sc-utils";
import { getUserAmount } from "helper/sc-utils/bond-sc-utils";
import { secondsToLabel } from "helper/dynamicHelpers";
import useIBOPoolParams from "hooks/useIBOPoolParams";

interface IBondTimeCounterProps {
  startDate: Date;
  endDate: Date;
  flow?: "row" | "column";
  numSize?: FontSize;
}

enum TimerStatus {
  pending,
  started,
  ended,
}

export function BondTimeCounter({
  startDate,
  endDate,
  numSize = "2md",
  flow = "row",
}: IBondTimeCounterProps) {
  const [status, setStatus] = useState(TimerStatus.pending);
  const [date, setDate] = useState(startDate);

  const handleOnComplete = () => {
    if (status === TimerStatus.pending) {
      // pending completed
      setStatus(TimerStatus.started);
      setDate(endDate);
    } else if (status === TimerStatus.started) {
      // timer ended
      setStatus(TimerStatus.ended);
    }
  };

  let text = "";
  switch (status) {
    case TimerStatus.pending:
      text = "Current bond pool offering starts in";
      break;
    case TimerStatus.started:
      text = "Current bond pool offering ends in";
      break;
    case TimerStatus.ended:
      text = "Current bond pool offering ended";
      break;
  }

  const countdown = useMemo(() => {
    return (
      <Countdown
        key={new Date(date).getTime()}
        date={new Date(date)}
        intervalDelay={0}
        precision={3}
        onComplete={handleOnComplete}
        renderer={({
          days,
          hours,
          minutes,
          seconds,
          completed,
          milliseconds,
        }) => {
          const milli = Math.round(
            milliseconds > 10 ? milliseconds / 10 : milliseconds
          );
          const _days = withZero(days);
          // const value = `${_days}:${withZero(hours)}:${withZero(minutes)}:${withZero(seconds)}:${withZero(milli)}`
          const value = `${_days}:${withZero(hours)}:${withZero(
            minutes
          )}:${withZero(seconds)}:${withZero(milli)}`;
          return completed ? (
            <Completionist />
          ) : (
            <AppTimeView
              numclassName={`p-5 px-7 fs-${numSize}`}
              value={value}
            />
          );
        }}
      />
    );
  }, [date]);

  return (
    <>
      <h5
        className={`text-center text-muted ${flow === "row" && "mb-15"} ${
          flow === "column" && "mb-0"
        }`}
      >
        {text}
      </h5>
      <div className="text-center fw-bold">{countdown}</div>
    </>
  );
}

interface IBondDetailsProps {
  bond: BondData;
  pairPriceUSD: string | number;
}
export function BondDetails({ bond, pairPriceUSD }: IBondDetailsProps) {
  const { appChainId } = useAppChain();
  const { account } = useWallet();
  const network = useMemo(() => NETWORKS[appChainId], [appChainId]);

  const poolParams = useIBOPoolParams({
    appChainId,
    classId: bond.classId,
    nonceId: bond.nonceId,
  });

  const [userAmounts, setUserAmounts] = useState<{
    principalAmount: string | number;
    availableAmount: string | number;
  }>({ availableAmount: "", principalAmount: "" });

  const { totalRaisedPairTokens, spentTokens, fee } = poolParams;

  const { supplyAmount, availableSupplyAmount, supplyAmountBN } =
    getSupplyAmount(
      bond.paramerters.supplyAmount,
      bond.paramerters.token.decimals,
      fee,
      spentTokens
    );

  const remainingAvailableAllocationInPercent: number = useMemo(() => {
    const value = (+supplyAmountBN - +spentTokens) / +supplyAmountBN;
    return value * 100;
  }, [supplyAmountBN, spentTokens]);

  const totalRaisedInPercent = useMemo<string>(() => {
    const value = 100 - remainingAvailableAllocationInPercent;
    return fromExponential(numberFormater(2).format(value));
  }, [totalRaisedPairTokens]);

  const tokenRatio = useMemo(() => {
    return getTokenRatio(
      bond.paramerters.token.address,
      [bond.poolParam.poolPair.token0, bond.poolParam.poolPair.token1],
      [bond.poolParam.poolPair.reserve0, bond.poolParam.poolPair.reserve1]
    );
  }, [bond]);
  const bondFaceValue = +pairPriceUSD * tokenRatio;

  const tokensSupply = supplyAmount;

  const myBalanceInDecimals = useMemo(() => {
    return decimalAmountToExactAmount(
      userAmounts.principalAmount,
      bond.paramerters.pairToken.decimals
    );
  }, [userAmounts.principalAmount]);

  const totalRaisedPairTokenUSD = useMemo(() => {
    return (
      decimalAmountToExactAmount(
        totalRaisedPairTokens,
        bond.paramerters.pairToken.decimals
      ) * +pairPriceUSD
    );
  }, [totalRaisedPairTokens]);

  const [initialTokenAmount, initialPairAmount] = useMemo<
    [string | number, string | number]
  >(() => {
    const position = bond.initialPositions[0];
    if (!position) return [0, 0];
    return [
      decimalAmountToExactAmount(
        position.tokenAmount,
        bond.paramerters.token.decimals
      ),
      decimalAmountToExactAmount(
        position.pairAmount,
        bond.paramerters.pairToken.decimals
      ),
    ];
  }, []);

  useAppDebounceEffect(
    () => {
      (async () => {
        if (!account) {
          return setUserAmounts({
            availableAmount: "0",
            principalAmount: "0",
          });
        }
        const _userAmounts = await getUserAmount(
          appChainId,
          bond.classId,
          bond.nonceId,
          account
        );
        setUserAmounts({
          availableAmount: _userAmounts.availableAmount,
          principalAmount: _userAmounts.principalAmount,
        });
      })();
    },
    300,
    [appChainId, account]
  );

  return (
    <div>
      <S.BondDetailBox>
        {/* dont remove following line. we will use in future */}
        {/* <DetailItem label='Bond type' value='50% discount' /> */}
        {/* Fund distributions is always 100% LP */}
        <DetailItem
          label="Fund distribution"
          value="100% LP"
          helpText={tooltip("Funds distributions")}
        />
        <DetailItem
          label="Pairs"
          value={`${bond.paramerters.token.symbol} <> ${bond.paramerters.pairToken.symbol}`}
        />
        <DetailItem label="Exchange" value="DEREX" />
        <DetailItem
          label="Bond face value"
          value={
            <TokenBalanceSpan prefix="$" digits={6}>
              {bondFaceValue}
            </TokenBalanceSpan>
          }
          helpText={tooltip("Bond face value")}
        />
        {/* Discount is always -50% */}
        <DetailItem
          label="Discount"
          value="-50%"
          helpText={tooltip("Discount")}
        />
        <DetailItem
          label="Principal vesting"
          value={`Clip before ${secondsToLabel(
            bond.paramerters.vestingParam.cliffDate
          )}`}
        />
        <DetailItem
          label="Prepayment penalty"
          value={bond.paramerters.vestingParam.prepaymentPenalty}
          helpText={tooltip("PREPAYMENT_PENALTY")}
        />
        <DetailItem
          label="Profits vesting period"
          value={secondsToLabel(bond.paramerters.vestingParam.cliffProfitDate)}
        />
        <DetailItem
          label="Accepting payment"
          value={bond.paramerters.pairToken.symbol}
        />
        <DetailItem
          label="Bonds supply available"
          value={availableSupplyAmount}
        />
        <DetailItem
          label="Investment limit per wallet"
          value={`${
            +bond.paramerters.maxInvestment > 0
              ? `${bond.paramerters.maxInvestment} ${bond.paramerters.pairToken.symbol}`
              : "No limit"
          }`}
        />
        <DetailItem
          label="Total Raised"
          value={
            <>
              <span className="text-primary">
                <TokenBalanceSpan prefix="$">
                  {totalRaisedPairTokenUSD}
                </TokenBalanceSpan>
              </span>
            </>
          }
        />
        <DetailItem
          label="Total Raised (% target)"
          value={`${totalRaisedInPercent}%`}
        />
        <DetailItem
          label="Remaining available allocation"
          value={
            <TokenBalanceSpan suffix="%" digits={4}>
              {remainingAvailableAllocationInPercent}
            </TokenBalanceSpan>
          }
        />
      </S.BondDetailBox>

      <Divider className="vertical mb-15 mt-10 highlighted-border-bottom" />

      <DetailItem
        label="Tokens supply"
        value={<TokenBalanceSpan>{tokensSupply}</TokenBalanceSpan>}
        helpText={tooltip("Tokens supply")}
      />
      <DetailItem
        label="FDV"
        value={
          <TokenBalanceSpan digits={6} prefix="$">
            {+tokensSupply * bondFaceValue}
          </TokenBalanceSpan>
        }
        helpText={tooltip("FDV")}
      />

      <Divider className="vertical my-15 highlighted-border-bottom" />

      <DetailItem
        label="Your investment"
        value={
          <span>
            <TokenBalanceSpan prefix="$">
              {+pairPriceUSD * myBalanceInDecimals}
            </TokenBalanceSpan>
            &nbsp;(
            <TokenBalanceSpan digits={6}>
              {myBalanceInDecimals}
            </TokenBalanceSpan>{" "}
            {bond.paramerters.pairToken.symbol})
          </span>
        }
      />
      <DetailItem
        label="Your initial LP assets"
        value={
          <>
            <TokenBalanceSpan
              suffix={` ${bond.paramerters.token.symbol}`}
              digits={6}
            >
              {initialTokenAmount}
            </TokenBalanceSpan>
            &nbsp;+&nbsp;
            <TokenBalanceSpan
              suffix={` ${bond.paramerters.pairToken.symbol}`}
              digits={6}
            >
              {initialPairAmount}
            </TokenBalanceSpan>
          </>
        }
      />
      <DetailItem
        label="Proof"
        value={
          <a
            href={`${network.ExplorerUrl}/tx/${bond.transactionHash}`}
            target="_blank"
            rel="noreferrer"
            className="text-secondary"
          >
            <ViewAddress address={bond.transactionHash} />
          </a>
        }
      />

      <div className="d-flex justify-content-between mt-25">
        {bond.info?.whitepaper && (
          <a
            href={bond.info.whitepaper}
            className="text-primary"
            rel="noreferrer"
            target="_blank"
          >
            View project whitepaper
            <FaExternalLinkAlt className="ml-5" />
          </a>
        )}
        {bond.info?.website && (
          <a
            href={bond.info.website}
            className="text-primary"
            rel="noreferrer"
            target="_blank"
          >
            View project website
            <FaExternalLinkAlt className="ml-5" />
          </a>
        )}
      </div>
    </div>
  );
}

interface ILaunchPoolItemProps extends React.HTMLAttributes<HTMLDivElement> {
  bondData: BondData;
  active?: boolean;
  tourEnable?: boolean;
  investDisabled?: boolean;
  onClickTweetButton: (
    viewId: string | number,
    { isCreator }: { isCreator: boolean; id: string }
  ) => void;
}

function IBOBondCard({
  bondData,
  investDisabled,
  active = true,
  tourEnable = false,
  onClickTweetButton,
  ...props
}: ILaunchPoolItemProps) {
  const [investValue, setInvestValue] = useState<string | number>("");

  const { appChainId } = useAppChain();
  const w3 = useWeb3();
  const [openWarningSnackbar] = useAppSnackbar(AppWaringOptions);
  const [openFTModal] = useFailedInfoModal();
  const [openLoadingModal, closeLoadingModal] = useProcessingModal();
  const { open: openTRModal } = useTransactionModal();
  const dispatch = useAppDispatch();
  const network = useMemo(() => NETWORKS[appChainId], [appChainId]);

  const poolParams = useIBOPoolParams({
    appChainId,
    classId: bondData.classId,
    nonceId: bondData.nonceId,
  });
  const [isInvalidPending, setIsInvalidPending] = useState(false);
  const [isInvalidInput, setIsInvalidInput] = useState(false);
  const [inputTimer, setInputTimer] = useState<
    ReturnType<typeof setTimeout> | undefined
  >(undefined);

  const { totalRaisedPairTokens, spentTokens, fee } = poolParams;
  const {
    id,
    viewId,
    classId,
    nonceId,
    paramerters: {
      token: { symbol, name, address },
      pairToken,
      targetAmount,
    },
    poolParam: { poolPair },
  } = bondData;
  const { logo, website, yeaVote = 0 } = bondData.info || {};
  const { availableSupplyAmount, availableSupplyAmountBN, supplyAmountBN } =
    getSupplyAmount(
      bondData.paramerters.supplyAmount,
      bondData.paramerters.token.decimals,
      fee,
      spentTokens
    );

  const pairVauleUSD = usePriceBySymbol(pairToken.symbol);
  const { account } = useWallet();
  const isCreator = useMemo(
    () => bondData.creator && account && isEqAddr(bondData.creator, account),
    [bondData.creator, account]
  );

  const tokenRatio = useMemo(() => {
    return getTokenRatio(
      address,
      [poolPair.token0, poolPair.token1],
      [poolPair.reserve0, poolPair.reserve1]
    );
  }, [
    address,
    poolPair.reserve0,
    poolPair.reserve1,
    poolPair.token0,
    poolPair.token1,
  ]);
  const revertedTokenRatio = useMemo(() => {
    return getRevertedTokenRatio(
      address,
      [poolPair.token0, poolPair.token1],
      [poolPair.reserve0, poolPair.reserve1]
    );
  }, [
    address,
    poolPair.reserve0,
    poolPair.reserve1,
    poolPair.token0,
    poolPair.token1,
  ]);
  const availableInvestmentAmount = useMemo<number>(() => {
    let reserve0 = poolPair.reserve0;
    let reserve1 = poolPair.reserve1;
    if (!isEqAddr(address, poolPair.token0.address)) {
      [reserve0, reserve1] = [reserve1, reserve0];
    }
    const availableAmount = availableSupplyAmount / (+reserve0 / +reserve1);
    if (
      bondData.paramerters.maxInvestment !== 0 &&
      bondData.paramerters.maxInvestment < availableAmount
    ) {
      return bondData.paramerters.maxInvestment;
    }
    return availableAmount;
  }, [
    poolPair,
    address,
    spentTokens,
    bondData.paramerters.maxInvestment,
    availableSupplyAmount,
  ]);

  const investmentLimitValueUSD = useMemo(
    () => fromExponential(pairVauleUSD * availableInvestmentAmount),
    [availableInvestmentAmount, pairVauleUSD]
  );

  const remainingAvailableAllocationInPercent: string = useMemo(() => {
    const value = (+supplyAmountBN - +spentTokens) / +supplyAmountBN;
    return (value * 100).toFixed(2);
  }, [supplyAmountBN, spentTokens]);
  const totalRaisedInPercent = useMemo<string>(() => {
    const value = 100 - +remainingAvailableAllocationInPercent;
    return fromExponential(numberFormater(2).format(value));
  }, [targetAmount, totalRaisedPairTokens]);

  const tRaisedInPForUserView: number = useMemo(() => {
    return Math.floor(+totalRaisedInPercent);
  }, [totalRaisedInPercent]);
  const remainingInPForUserView: number = useMemo(() => {
    return Math.floor(+remainingAvailableAllocationInPercent);
  }, [remainingAvailableAllocationInPercent]);

  const onClickVote = async () => {
    try {
      await dispatch(bondActions.voteBond(classId, nonceId));
    } catch (error: any) {
      openWarningSnackbar(error.message || String(error));
    }
  };

  const handleOnChangeInvestInput = (value: number | string) => {
    setIsInvalidInput(false);
    setIsInvalidPending(true);
    inputTimer && clearTimeout(inputTimer);
    const _timer = setTimeout(async () => {
      try {
        const w3Helpers = new Web3Helpers(w3, account);
        const _pairTokenBalance = await w3Helpers.getTokenBalance(
          isEqAddr(pairToken.address, network.W_TOKEN_ADDRESS)
            ? ZERO_ADDRESS
            : pairToken.address,
          account as string
        );
        const _pairTokenBalanceNum = decimalAmountToExactAmount(
          _pairTokenBalance,
          pairToken.decimals
        );
        if (+investValue > +_pairTokenBalanceNum) {
          setIsInvalidInput(true);
          return openWarningSnackbar(
            "Insufficient token balance! you have only " +
              _pairTokenBalanceNum +
              " to invest."
          );
        }
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setIsInvalidPending(false);
        setInputTimer(undefined);
      }
    }, 500);
    setInvestValue(value);
    setInputTimer(_timer);
  };

  const invest = async () => {
    try {
      if (isInvalidInput || isInvalidPending) return;
      if (!investValue) {
        setIsInvalidInput(true);
        return openWarningSnackbar("Input amount to invest!");
      }
      if (!availableSupplyAmount) {
        setIsInvalidInput(true);
        return openWarningSnackbar("No Supply!");
      }
      openLoadingModal();
      await dispatch(
        buyBond(
          pairToken.address,
          pairToken.decimals,
          classId,
          nonceId,
          +investValue
        )
      );
      await dispatch(bondActions.reFetchBonds());
    } catch (error: any) {
      console.error(error);
      openFTModal(error.response?.data || error.message || error);
    } finally {
      closeLoadingModal();
    }
  };

  const handleOnClickWithdraw = async () => {
    try {
      openLoadingModal();
      if (availableSupplyAmount > 0) {
        await dispatch(
          withdrawProjectTokens(classId, nonceId, availableSupplyAmountBN)
        );
        await dispatch(bondActions.reFetchBonds());
      } else {
        openWarningSnackbar("There is no supply!");
      }
    } catch (error: any) {
      console.error(error);
      openFTModal(error.response?.data || error.message || error);
    } finally {
      closeLoadingModal();
    }
  };

  const handleOnClickMaxButton = async () => {
    let tokenAddr = pairToken.address;
    if (isEqAddr(network.W_TOKEN_ADDRESS, pairToken.address))
      tokenAddr = ZERO_ADDRESS;
    const w3Helper = new Web3Helpers(w3, account);

    const bal = await w3Helper.getTokenBalance(tokenAddr);
    const nBal = decimalAmountToExactAmount(bal, pairToken.decimals);
    if (!nBal) {
      openWarningSnackbar("Insufficient token balance");
    } else if (!bondData.paramerters.maxInvestment) {
      setInvestValue(nBal);
    } else if (
      bondData.paramerters.maxInvestment &&
      bondData.paramerters.maxInvestment < nBal
    ) {
      setInvestValue(bondData.paramerters.maxInvestment);
    } else if (
      bondData.paramerters.maxInvestment &&
      bondData.paramerters.maxInvestment > nBal
    ) {
      setInvestValue(nBal);
    }
  };
  const handleOnClickLaunchPool = async () => {
    // check if the pool is public
    const lpHelper = new BSwapPairHelper(w3);
    try {
      openLoadingModal();

      const isPrivate = await lpHelper.isPrivate(poolPair.lpAddress);
      if (!isPrivate) {
        // it is public now
        return openWarningSnackbar("The Pool is already launched!");
      }
      // const result = await lpHelper.switchToPublic(
      //   poolPair.lpAddress,
      //   account as string
      // );

      await dispatch(closeOffer(classId, nonceId));

      await dispatch(bondActions.reFetchBonds());

      // openTRModal(result.transactionHash);
      // @ts-ignore
    } catch (error: Error) {
      openFTModal("Something went wrong!! \nError Code: \n " + error.message);
    } finally {
      closeLoadingModal();
    }
  };

  const hanldeOnClickRunBondOnTwitter = () => {
    onClickTweetButton(viewId, { isCreator: Boolean(isCreator), id: id });
  };

  const tokenIconElt = useMemo(() => {
    return (
      <TokenIcon
        className="rounded-circle bg-white"
        src={logo}
        byAddress={address}
        alt={`Logo-${address}`}
        style={{ width: 80, height: 80 }}
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
      />
    );
  }, [address, logo]);

  return (
    <React.Fragment>
      <Box
        className="hover:border-highlight-colorPrimary border-0 position-relative"
        {...props}
      >
        <div className="pt-25 px-25">
          <AppLink
            to={`${AppRouters.IBO_BONDS_PAGE}/${id}/details`}
            className="py-8 px-10 text-muted position-absolute left-2 -top-2"
            target="_blank"
          >
            <FaExternalLinkAlt className="" size={12} />
          </AppLink>
          {active && (
            <S.SVote onClick={onClickVote}>
              <FaThumbsUp />
              {/* @ts-ignore */}
              <span className="pl-5">{parseInt(yeaVote || 0)}</span>
            </S.SVote>
          )}
          <S.ProjectDetailBox>
            <div className="ProjectDetailBox-Info">
              {tokenIconElt}
              <div className="ProjectDetailBox-Info-Details">
                <S.ProjectSymbol>{symbol}</S.ProjectSymbol>
                <S.ProjectName href={website} target="_blank" rel="noreferrer">
                  {name}
                </S.ProjectName>
              </div>
            </div>
            <Divider className="horizontal px-0 highlighted-border-right" />
            <div className="fs-12 ProjectDetailBox-Detail">
              <BondInfoItem icon={<FaPercentage />} label="50% Discount" nobg />
              {bondData.paramerters.maxInvestment > 0 && (
                <BondInfoItem
                  icon={<IoLogoUsd />}
                  label="Investment Limits" /*helpText={tooltip('Investment Limits')} */
                />
              )}
              {+bondData.paramerters.vestingParam.cliffDate > 0 && (
                <BondInfoItem
                  icon={<FaLock size={13} />}
                  label="Vesting Duration"
                  nobg
                />
              )}
            </div>
          </S.ProjectDetailBox>
          <div className="fs-sm mt-5">
            <p className="text-center text-primary-2 mb-5">
              Total raised {totalRaisedInPercent}%
            </p>
            {/* @ts-ignore */}
            <AppSlider
              className="primary-1 mb-5"
              value={tRaisedInPForUserView}
            />
            {/* @ts-ignore */}
            <AppSlider
              className="primary mb-5"
              value={100 - remainingInPForUserView}
            />
            <p className="text-primary text-center">
              Remaining available allocation&nbsp;
              <TokenBalanceSpan suffix="%" digits={2}>
                {remainingAvailableAllocationInPercent}
              </TokenBalanceSpan>
            </p>
          </div>
          {active ? (
            <>
              <S.SecureFloorDetailBox className="my-25 text-center">
                <div className="px-20 SecureFloorDetailBox-FloorSection">
                  <h5
                    className="fw-bold"
                    data-tour={
                      tourEnable ? "ibo-tour-secure-floor-protection" : ""
                    }
                  >
                    Secure Floor Protection
                    <BsQuestionCircleFill
                      className="fas helpIco"
                      data-type="light"
                      data-html="true"
                      data-class="data-tooltip"
                      data-tip={tooltip("Secure Floor Protection")}
                    />
                  </h5>
                  <h5 className="text-primary text-nowrap">
                    100% [1&nbsp;{pairToken.symbol} {" <> "}{" "}
                    <TokenBalanceSpan digits={6}>
                      {revertedTokenRatio}
                    </TokenBalanceSpan>{" "}
                    {symbol}]
                  </h5>
                </div>
                <Divider className="horizontal highlighted-border-right h-min-30" />
                <div
                  className="px-30 SecureFloorDetailBox-DiscountSection"
                  data-tour={tourEnable ? "ibo-tour-secure-discount" : ""}
                >
                  <h5 className="fw-bold">
                    Discount
                    <BsQuestionCircleFill
                      className="fas helpIco"
                      data-type="light"
                      data-html="true"
                      data-class="data-tooltip"
                      data-tip={tooltip("BondDiscount")}
                    />
                  </h5>
                  {/* discount is always 50% */}
                  <h5 className="text-muted">-50%</h5>
                </div>
              </S.SecureFloorDetailBox>
              <BondTimeCounter
                startDate={new Date(bondData.paramerters.startDate)}
                endDate={new Date(bondData.paramerters.endDate)}
              />
              <h5 className="text-center text-muted my-20">
                1 {symbol} ={" "}
                <AmountLabel digits={4}>
                  {tokenRatio * pairVauleUSD}
                </AmountLabel>
                , 1 {pairToken.symbol} ={" "}
                <AmountLabel digits={4}>{pairVauleUSD}</AmountLabel>
              </h5>
            </>
          ) : (
            <div className="my-20">
              <p className="text-colorRedHover fw-bolder fs-lg text-center">
                {totalRaisedInPercent}% Sold Out
              </p>
            </div>
          )}

          {active ? (
            <>
              <InvestInput
                tokenAddress={pairToken.address}
                valueInUsd={+investmentLimitValueUSD}
                investDisabled={investDisabled}
                isLoading={isInvalidPending}
                isInvalid={isInvalidInput}
                max={{
                  symbol: pairToken.symbol,
                  value: availableInvestmentAmount,
                }}
                value={investValue}
                hideWithdraw={!isCreator}
                onChangeInvestInput={handleOnChangeInvestInput}
                onClickWithdraw={handleOnClickWithdraw}
                onClickMax={handleOnClickMaxButton}
                onClickInvest={invest}
              />
            </>
          ) : (
            <>
              <AppButton
                className={`text-primary fs-lg outline-gray ${
                  isCreator ? "visible" : "invisible"
                }`}
                onClick={handleOnClickLaunchPool}
              >
                Launch The Pool
              </AppButton>
              <div className="text-end my-5">
                <button
                  className={`text-danger fs-12 mb-15 fw-bold ${
                    isCreator ? "visible" : "invisible"
                  }`}
                  onClick={handleOnClickWithdraw}
                >
                  Withdraw supply
                </button>
              </div>
            </>
          )}
          <Divider className="vertical highlighted-border-bottom" />
        </div>
        <S.LaunchPoolAccordion allowZeroExpanded className="mt-20">
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="d-flex justify-content-between align-items-center fs-md text-white">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center m-0">
                    <StatusIcon className={`${active ? "active" : "ended"}`} />
                    <span className="ml-10">
                      {active ? "LIVE" : "ENDED"} #{viewId}
                    </span>
                  </div>
                  <Divider className="horizontal h-initial" />
                  {active && (
                    <>
                      <span className="ml-10">Exchange:</span>
                      <img
                        src={"/images/DEREX-footer.png"}
                        alt="DEREX-footer.svg"
                        style={{ width: 30, height: 30 }}
                      />
                    </>
                  )}
                </div>
                <div
                  data-tour={
                    tourEnable ? "ibo-tour-investment-details-parent" : ""
                  }
                >
                  <AccordionItemState>
                    {({ expanded }) =>
                      expanded ? (
                        <>
                          Hide <IoIosArrowUp className="-mt-5" size={18} />
                        </>
                      ) : (
                        <span
                          data-tour={
                            tourEnable ? "ibo-tour-investment-details" : ""
                          }
                        >
                          Details <IoIosArrowDown size={18} />
                        </span>
                      )
                    }
                  </AccordionItemState>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="mt-20 fs-12 text-muted">
              <S.LaunchPoolAccordionBondDetails>
                <BondDetails bond={bondData} pairPriceUSD={pairVauleUSD} />
              </S.LaunchPoolAccordionBondDetails>

              <Divider className="vertical mt-15 highlighted-border-bottom" />
              {
                // isCreator ? (
                <S.TwitterBox
                  className="creator"
                  onClick={hanldeOnClickRunBondOnTwitter}
                >
                  <AiOutlineTwitter className="twitter-icon" />
                  <span className="twitter-content">
                    Run this bond offering on your Twitter
                    <br /> and gain 2.5% fee from total bond tokens
                  </span>
                </S.TwitterBox>
                // ) : (
                //   <S.TwitterBox>
                //     <button className='viewer-buttons'>
                //       <AiOutlineTwitter className='twitter-icon' />
                //       <span className='twitter-content'>
                //         Send message
                //       </span>
                //     </button>
                //     <Divider className='horizontal highlighted-border-right' />
                //     <button className='viewer-buttons'>
                //       <BiDetail className='twitter-icon' />
                //       <span className='twitter-content'>
                //         View details
                //       </span>
                //     </button>
                //   </S.TwitterBox>
                // )
              }
            </AccordionItemPanel>
          </AccordionItem>
        </S.LaunchPoolAccordion>
        <AppTooltip />
      </Box>
    </React.Fragment>
  );
}

export default IBOBondCard;
