import React, { useCallback, useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { getDMCPrice } from "helper";
import AppLink from "Component/AppLink";
import { ZERO_ADDRESS, DMC_TOKEN_ADDRESS } from "app-constants";
import * as S from "./styled";
import {
  AppFooterLogo,
  AppQonetumFooterLogo,
} from "Component/Headers/AppLogos";
import { navs } from "Component/Headers/AppHeader";

const Footer: React.FC = () => {
  const [dmcPrice, setDmcPrice] = useState<number>(0);
  const location = useLocation();
  const appFooterRef = useRef<null | HTMLDivElement>(null);

  const fetchAndSetDmcPrice = useCallback(async () => {
    const dmcValue = await getDMCPrice();
    setDmcPrice(+dmcValue);
  }, []);

  /**
   * @description use to adjust page responsive of footer and header
   */
  const adjustSectionHeights = useCallback(() => {
    // should be run after loading pages. and data.
    setTimeout(() => {
      const footerHeight =
        appFooterRef.current?.offsetHeight ||
        appFooterRef.current?.clientHeight ||
        0;
      const appSection = document.getElementById("app-section");
      appSection &&
        appSection.style.setProperty("padding-bottom", `${footerHeight + 0}px`);
    }, 1500);
  }, [appFooterRef]);

  useEffect(() => {
    fetchAndSetDmcPrice();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      fetchAndSetDmcPrice();
    }, 1000 * 60);

    return () => {
      clearInterval(timer);
    };
  }, [fetchAndSetDmcPrice]);

  useEffect(() => {
    adjustSectionHeights();
    window.addEventListener("resize", adjustSectionHeights);

    return () => {
      window.removeEventListener("resize", adjustSectionHeights);
    };
  }, [adjustSectionHeights]);

  return (
    <div className="app-footer" ref={appFooterRef}>
      <S.FooterMain className="z-index-5">
        <S.FooterLeft>
          <AppFooterLogo navs={navs} />
          <div className="footer-detail">
            {/* <img src={Icon6} alt='' /> */}
            <S.ValueText>${dmcPrice.toFixed(6)}</S.ValueText>
            <Link
              to={`/swap?chain=56&tokenA=${ZERO_ADDRESS}&tokenB=${DMC_TOKEN_ADDRESS}`}
              target="_blank"
            >
              DRX
            </Link>
          </div>
        </S.FooterLeft>
        <S.FooterMiddle>
          <S.FooterMiddleUpper>
            <a
              href="https://qwantum-finance-labs.gitbook.io/derex-exchange/overview/derex-exchange"
              target="_blank"
              rel="noreferrer"
            >
              White Paper
            </a>
            <span></span>
            <AppLink
              to="/reimbursement"
              target="_blank"
              tabIndex={3}
              className={`${location.pathname === "#" ? "active" : ""}`}
            >
              100% gas and fees reimbursement
            </AppLink>
          </S.FooterMiddleUpper>
        </S.FooterMiddle>
        <S.FooterRight>
          <p className="fs-md mb-0 text-muted">Powered by</p>
          <AppQonetumFooterLogo navs={navs} />
        </S.FooterRight>
      </S.FooterMain>
    </div>
  );
};

export default Footer;
